import {safeText} from "../../js/util/CommonUtils.js";
import {getUserIcon} from "../../js/util/UserUtils.js";
import {UserMenuItemId} from "./UserMenuItemId.js";
import {createOrgNameUrlTemplate} from "../../js/util/UrlUtils.js";

export default class UserMenuHtml {
    /** @type {HTMLElement} */
    #parent;
    /** @type {UserWithOrgs} user */
    #user;
    #hideMenuFunction;
    /** @type {OrgSideUserMenu} */
    #orgSideUserMenu;

    constructor() {
        this.#hideMenuFunction = this.#hide.bind(this);
    }

    /**
     * @param {HTMLElement} parent
     * @param {UserWithOrgs} user
     * @param {OrgSideUserMenu} orgSideUserMenu
     */
    init(parent, user, orgSideUserMenu) {
        this.#parent = parent;
        this.#user = user;
        this.#orgSideUserMenu = orgSideUserMenu;
        this.#parent.insertAdjacentHTML('beforeend', this.#template(user));
        this.#initSideMenu(user);
        this.#setListenersToMenuItems();
    }

    /** @param {function} cb */
    onOpenCreateOrgDialogClick(cb){
        this.#addOnClickCallbackIfElementExists(this.#getCreateOrgDialogItem(), cb);
    }

    /** @param {function} cb */
    onGlobalSettingsClick(cb){
        const menuItem = this.#getGlobalSettingsItem()
        if (this.#parent.contains(menuItem) && !menuItem.hasAttribute("disabled"))
            this.#addOnClickCallbackIfElementExists(menuItem, cb);
    }

    #show() {
        this.#getMenuElement().addEventListener('click', this.#hideMenuFunction);
        this.#getMenuElement().showModal();
    }

    /** @param {Event} event */
    #hide(event) {
        event.stopPropagation()
        this.#getMenuElement().close();
        this.#getMenuElement().removeEventListener('click', this.#hideMenuFunction);
        this.#orgSideUserMenu.close()
    }

    /** @returns {boolean} */
    #isVisible() {
        return this.#getMenuElement().hasAttribute('open');
    }

    /** @returns {HTMLDialogElement} */
    #getMenuElement() {
        return this.#parent.querySelector('.user-menu');
    }

    /** @returns {HTMLElement} */
    #getCreateOrgDialogItem() {
        return this.#parent.querySelector(`#${UserMenuItemId.CREATE_ORG}`);
    }

    /** @returns {HTMLElement} */
    #getGlobalSettingsItem() {
        return this.#parent.querySelector(`#${UserMenuItemId.GLOBAL_SETTINGS}`);
    }

    /** @returns {HTMLElement} */
    #getOrgSideMenuEl() {
        return this.#parent.querySelector(`#${UserMenuItemId.ORGS}`)
    }

    /** @param {UserWithOrgs} user */
    #initSideMenu(user){
        if (user.orgs.length > 3){
            this.#orgSideUserMenu.init(this.#parent, user.orgs)
        }
    }

    /** @param {Event} event */
    #toggleMenuVisibility(event){
        event.stopPropagation();
        if (this.#isVisible())
            this.#hide(event);
        else
            this.#show();
    }

    /**
     * @param {HTMLElement} menuItemElement
     * @param cb
     */
    #addOnClickCallbackIfElementExists(menuItemElement, cb){
        if (this.#parent.contains(menuItemElement) && !menuItemElement.hasAttribute("disabled"))
            menuItemElement.addEventListener('click', cb);
    }

    #setListenersToMenuItems() {
        // This listener allows us to close user menu on click on backdrop of user menu
        this.#parent.addEventListener('click', (event) => {
            this.#toggleMenuVisibility(event)
        })
        // This listener stops closing user menu when click inside user menu
        this.#parent.querySelector('.user-menu__menu-wrapper')
            .addEventListener('click', (event)=>{event.stopPropagation()});
        // These listeners will close menu when click on menu items, so we add listeners only on action menu-items, which
        // opens dialog or do some other action
        const actionMenuItems = this.#parent.querySelectorAll('.user-menu__action-item');
        for (const item of actionMenuItems){
            if (!item.hasAttribute('disabled')) {
                item.addEventListener('click', this.#hide.bind(this))
            }
        }
        const orgSideMenuEl = this.#getOrgSideMenuEl();
        if (orgSideMenuEl){
            orgSideMenuEl.addEventListener('click', ()=>this.#orgSideUserMenu.show())
        }
    }

    /** @param {Org[]} orgs */
    #orgSideMenuTemplate(orgs){
        if (orgs.length <= 3) {
            return`<li class="user-menu__section-title user-menu__section-title--org">Your organizations</li>
                   ${orgs.map(org => `<li class="user-menu__menu-item user-menu__menu-item--link user-menu__menu-item--org">
                        <a href="/a/${org.name}" class="user-menu__link">${safeText(org.displayName)}</a>
                   </li>`).join('')}
                   <li class="user-menu__separator"></li>`
        } else {
            return `<li id="${UserMenuItemId.ORGS}" class="user-menu__menu-item user-menu__menu-item--with-side-menu">
                <span>Your organizations</span><span class="material-icons user-menu__arrow-icon">chevron_right</span>
            </li>`
        }
    }

    /**
     * @param {UserWithOrgs} user
     * @returns {string}
     */
    #template(user) {
        return `
            <dialog class="user-menu">
                <menu class="user-menu__menu-wrapper">
                    <li id="menu-header" class="user-menu__header user-menu__menu-item user-menu__menu-item--link"><a href="${createOrgNameUrlTemplate(user.basicUser.name)}" class="user-menu__link"> 
                        ${getUserIcon(user.basicUser, 'user-menu__initials-icon')}
                        <div class="user-menu__name">${safeText(user.basicUser.displayName)}</div></a>
                    </li>
                    <li class="user-menu__separator"></li>
                    ${user.orgs.length ? this.#orgSideMenuTemplate(user.orgs) : ''}
                    ${user.capabilities.canCreateOrgs ? `<li id="${UserMenuItemId.CREATE_ORG}" class="user-menu__menu-item user-menu__action-item">Create organization</li>` : ''}
                    ${user.isAdmin() ? `<li id="${UserMenuItemId.GLOBAL_SETTINGS}" class="user-menu__menu-item user-menu__action-item user-menu__menu-item--link"><a href="${window.location.origin}/settings" class="user-menu__link">Global settings</a></li>` : ''}
                </menu>
            </dialog>`
    }
}

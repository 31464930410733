import {safeText} from "../../js/util/CommonUtils.js";
import StringToColorConverter from "../../js/util/StringToColorConverter.js";

export default class UserHeaderHtml {
    /** @type {HTMLElement} */
    #rootEl;

    /** @param {HTMLElement} rootEl */
    init(rootEl) {
        this.#rootEl = rootEl;
    }

    /** @param {UserWithOrgs} user */
    show(user) {
        const color = StringToColorConverter.convert(user.basicUser.id);
        this.#rootEl.insertAdjacentHTML('afterbegin', `
            <div class="user-section"> 
                <button data-id="user-name" class="user-section__button-name account-icon" style="background: ${color}; border-color: ${color}">
                    <div class="user-section__initials user-icon__initials">${safeText(user.basicUser.initials)}</div>
                </button>
            </div>
        `)
    }
}
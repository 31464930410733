import FileDropAreaHtml from './FileDropAreaHtml.js'

export default class FileDropAreaCmp {
	constructor() {
		this._html = new FileDropAreaHtml();
	}

	/**
	 * @param {Element} element
	 * @param {{svgIcon?: string, message?:string, showOverlay?:boolean}?} props
	 */
	init(element, props = {}) {
		this._html.init(element, props);
	}

	onDragEnter(cb) {
		this._html.onDragEnter(cb);
	}

	onDragLeave(cb) {
		this._html.onDragLeave(cb);
	}

	onDrop(cb) {
		this._html.onDrop(cb);
	}
}

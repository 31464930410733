export default class PermissionOnResourceError extends Error {
    /** @type {string} */
    #resourceId;
    /** @type {number} */
    #errorCode;
    /** @type {string} */
    #errorMessage;

    /**
     * @param {string} resourceId
     * @param {number} code
     * @param {string} description
     */
    constructor(resourceId, code, description) {
        super();
        this.#resourceId = resourceId;
        this.#errorCode = code;
        this.#errorMessage = description;
    }

    /** @returns {string} */
    get resourceId(){
        return this.#resourceId;
    }

    /** @returns {number} */
    get errorCode(){
        return this.#errorCode
    }

    /** @returns {string} */
    get errorMessage() {
        return this.#errorMessage;
    }

    /** @param {string} message */
    set errorMessage(message){
        this.#errorMessage = message
    }

    /** @returns {boolean} */
    isResourceForbidden() {
        return this.#errorCode === 403;
    }

    static parseServerJson(json){
        return new PermissionOnResourceError(json.resource, json.error.code, json.error.description)
    }
}
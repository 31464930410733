import OperationNotAllowedError from "./OperationNotAllowedError.js";
import HttpError from "./HttpError.js";
import PermissionOnResourceError from "./PermissionOnResourceError.js";
import PermissionOnResourceErrorHandler from "./PermissionOnResourceErrorHandler.js";

export default class GlobalErrorHandler {
    #errorHandlers = {
        operationNotAllowed: [],
        httpError: [],
        permissionOnResourceError: [
            new PermissionOnResourceErrorHandler()
        ],
    };

    init() {
        window.onerror = (message, source, lineno, colno, error) => {
            this.#handleError(error);
            // return true to prevent error from being displayed in the console
            return true;
        };
        window.onunhandledrejection = (event) => {
            event.stopPropagation();
            event.preventDefault();
            const error = event.reason;
            this.#handleError(error);
        };
    }

    addOperationNotAllowedErrorHandler(cb) {
        this.#errorHandlers.operationNotAllowed.push(cb);
    }

    addHttpErrorHandler(cb) {
        this.#errorHandlers.httpError.push(cb);
    }

    /** @param {PermissionOnResourceErrorHandler} handler */
    addPermissionOnResourceErrorHandler(handler){
        this.#errorHandlers.permissionOnResourceError.push(handler);
    }

    #handleError(error) {
        if (error instanceof OperationNotAllowedError)
            this.#errorHandlers.operationNotAllowed.forEach(handler => handler.handle(error));
        else if (error instanceof HttpError)
            this.#errorHandlers.httpError.forEach(handler => handler.handle(error));
        else if (error instanceof PermissionOnResourceError)
            this.#errorHandlers.permissionOnResourceError.forEach(handler => handler.handle(error));
        else {
            window.lastUnHandledPeakselError = error;
            console.error(error)
        }
    }
}
import UserMenuHtml from "./UserMenuHtml.js";
import CreateOrgDialogCmp from "../CreateOrgDialog/CreateOrgDialogCmp.js";
import OrgSideUserMenu from "./OrgSideUserMenu.js";

export default class UserMenuCmp {
    /** @type {UserMenuHtml} */
    #html = new UserMenuHtml();
    /** @type {OrgRepository}  */
    #orgRepo;
    /** @type {CreateOrgDialogCmp} */
    #createOrgDialog = new CreateOrgDialogCmp();
    /** @type {OrgSideUserMenu} */
    #orgSideUserMenu = new OrgSideUserMenu();

    /**
     * @param {HTMLElement} parent
     * @param {UserWithOrgs} user
     * @param {OrgRepository} orgRepo
     */
    init(parent, user, orgRepo) {
        this.#html.init(parent, user, this.#orgSideUserMenu);
        this.#orgRepo = orgRepo;
        this.#createOrgDialog.onSave(this.#createOrganization.bind(this))
        this.#html.onOpenCreateOrgDialogClick(this.#openCreateOrgDialog.bind(this));
    }

    #openCreateOrgDialog() {
        this.#createOrgDialog.show();
    }

    /**
     * @param {string} name
     * @return {Promise<void>}
     */
    async #createOrganization(name) {
        try {
            const resp = await this.#orgRepo.createOrganization(name);
            this.#createOrgDialog.remove();
            window.location.href = `${window.location.origin}/a/${resp.name}`
        } catch (error) {
            if (error.isBadRequest)
                this.#createOrgDialog.showInputErrors(error.response.errors)
            else throw error
        }
    }
}
export default class SecurityContext {
    #currentUser;
    #signUpUrl;
    #signInUrl;

    constructor(currentUser, signUpUrl, signInUrl) {
        this.#currentUser = currentUser;
        this.#signUpUrl = signUpUrl;
        this.#signInUrl = signInUrl;
    }

    /**
     * @returns {boolean}
     */
    isAuthenticated() {
        return this.#currentUser !== null;
    }

    /**
     * @returns {UserWithOrgs | null}
     */
    getCurrentUser() {
        return this.#currentUser;
    }

    /**
     * @returns {string}
     */
    get signUpUrl() {
        return this.#signUpUrl;
    }

    /**
     * @returns {string}
     */
    get signInUrl() {
        return this.#signInUrl;
    }
    /**
     * @returns {string}
     */
    get mainPageUrl() {
        return '/';
    }
}
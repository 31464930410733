import UserJson from "./UserJson.js";
import Team from "../Team.js";

export default class UserWithTeams {
    /** @type {UserJson} */
    #user;
    /** @type {Team[]} */
    teams

    /**
     * @param {UserJson} user
     * @param {Team[]} teams
     */
    constructor(user, teams) {
        this.#user = user;
        this.teams = teams.sort((a, b) => a.name.localeCompare(b.name))
    }

    /** @returns {UserJson} */
    get user(){
        return this.#user;
    }

    /** @returns {string} */
    getTeamsLikeString(){
        return this.teams.map(team => team.name).join(', ');
    }

    /** @returns {boolean} */
    isInAdminTeam(){
        for (const team of this.teams) {
            if (team.isAdminTeam())
                return true
        }
        return false
    }

    /**
     * @param json
     * @returns {UserWithTeams}
     */
    static parseServerJson(json) {
        return new UserWithTeams(UserJson.parseServerJson(json), Team.parseServerJsonMany(json.teams))
    }

    /**
     * @param usersJson
     * @returns {UserWithTeams[]}
     */
    static parseServerJsonMany(usersJson) {
        const result = [];
        usersJson.forEach(json => result.push(UserWithTeams.parseServerJson(json)));
        return result
    }
}
export default class PeakDetectorProperties {
    constructor(algorithmName, curvatureThreshold, liftoffThreshold, touchdownThreshold,
                smootherName, windowSize, polynomialOrder) {
        this._algorithmName = algorithmName;
        this._props = {
            curvatureThreshold: curvatureThreshold,
            liftoffThreshold: liftoffThreshold,
            touchdownThreshold: touchdownThreshold
        };
        this._smoother = {
            algorithmName: smootherName,
            props: {
                windowSize: windowSize,
                polynomialOrder: polynomialOrder
            }
        }
    }

    copy() {
        return new PeakDetectorProperties(this._algorithmName,
            this._props.curvatureThreshold, this._props.liftoffThreshold, this._props.touchdownThreshold,
            this._smoother.algorithmName, this._smoother.props.windowSize, this._smoother.props.polynomialOrder
        );
    }
    /**
     * @param {PeakDetectorProperties} other
     * return {boolean}
     */
    equals(other) {
        if(other === this)
            return true;
        if(!other)
            return false;
        if(other.constructor.name !== this.constructor.name)
            return false;
        return JSON.stringify(this) === JSON.stringify(other);
    }

    get algorithmName() {
        return this._algorithmName;
    }

    get smootherName() {
        return this._smoother.algorithmName;
    }

    get windowSize() {
        return this._smoother.props.windowSize
    }

    get polynomialOrder() {
        return this._smoother.props.polynomialOrder
    }

    get liftoffThreshold() {
        return this._props.liftoffThreshold
    }

    get touchdownThreshold() {
        return this._props.touchdownThreshold
    }

    get curvatureThreshold() {
        return this._props.curvatureThreshold
    }

    toServerJson() {
        return {
            algorithmName: this._algorithmName,
            props: {
                curvatureThreshold: this.curvatureThreshold,
                liftoffThreshold: this.liftoffThreshold,
                touchdownThreshold: this.touchdownThreshold
            },
            smoother: {
                algorithmName: this._smoother.algorithmName,
                props: {
                    windowSize: this.windowSize,
                    polynomialOrder: this.polynomialOrder
                }
            }
        }
    }

    static parseServerJson(json) {
        return new PeakDetectorProperties(json.algorithmName,
            +json.props.curvatureThreshold, +json.props.liftoffThreshold,
            +json.props.touchdownThreshold, json.smoother.algorithmName, +json.smoother.props.windowSize,
            +json.smoother.props.polynomialOrder);
    }

    static DEFAULT_VALUES = {
        POLYNOMIAL_ORDER: 2
    }
}
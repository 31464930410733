import UserJson from "../entities/user/UserJson.js";

export default class UserRepository {
    #httpClient;
    #endpointUrl;

    constructor(httpClient, endpointUrl = '/api/user') {
        this.#httpClient = httpClient;
        this.#endpointUrl = endpointUrl;
    }

    /**
     * @param {string} name
     * @param {string} teamId
     * @param {string|null} orgId
     * @returns {Promise<UserJson[]>}
     */
    async getMatchedUsers(name, teamId, orgId) {
        const orgCandidateParam = orgId ? `&orgCandidate=${encodeURIComponent(orgId)}` : '';
        const teamCandidateParam = teamId ? `&teamCandidate=${encodeURIComponent(teamId)}` : '';
        const resp = await this.#httpClient
            .fetch(`${this.#endpointUrl}?name=${encodeURIComponent(name)}${teamCandidateParam}${orgCandidateParam}`,
            {
                method: 'GET',
                headers: {
                    "Accept": "application/json"
                },
                responseType: 'json',
            });
        return UserJson.parseServerJsonMany(resp);
    }
}

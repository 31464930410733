export default class Wavelength{
  constructor(nm, precision, refWl, refWlPrecision) {
    this.nm = nm;
    this.precision = precision;
    this.refWl = refWl;
    this.refWlPrecision = refWlPrecision;    
  }
  getDisplayName(){return this.nm}
  /** @return {string} */
  getDescription(){
    const refWl = this.refWl ? ` - ${this.refWl}&pm;${this.refWlPrecision}` : '';
    return `${this.nm}&pm;${this.precision}${refWl}`
  }

  withWlBand(wlBand){
    return new Wavelength(this.nm, wlBand, this.refWl, this.refWlPrecision);
  }
  static parseServerJson(json){
    return json ? new Wavelength(json.nm, json.precision, json.refWl, json.refWlPrecision) : null;
  }
}

export default class IntegrationPeakFilters {
    /**
     * @param {number} minPeakArea
     */
    constructor(minPeakArea) {
        this.minPeakArea = minPeakArea;
    }

    /** @return {IntegrationPeakFilters} */
    copy() {
        return new IntegrationPeakFilters(this.minPeakArea);
    }

    /**
     * @param {IntegrationPeakFilters} other
     * @return {boolean}
     */
    equals(other) {
        if(other === this)
            return true;
        if(!other)
            return false;
        if(other.constructor.name !== this.constructor.name)
            return false;
        return other.minPeakArea === this.minPeakArea;
    }
}
import MassRange from './MassRange.js';
import Wavelength from './Wavelength.js';
import {floatPointsFromBase64String} from '../util/CommonUtils.js';
import IntegrationScope from "./chromatogram/integration/IntegrationScope.js";
import Range from "./Range.js";
import EicExtractionProps from "../../components/massSpec/EicExtractionProps.js";

export default class ChromatogramJson{
  constructor(id, domainId, signalId, detectorRunId, isTotalSignal, substanceId, massRange, wl, wlColor,
              baselineAnchors, signalRange, name, eicExtractionProps) {
    /** @type {string} */
    this._chromatogramId = id;
    /** @type {string} */
    this._domainBlobId = domainId;
    /** @type {string} */
    this._signaBloblId = signalId;
    /** @type {string} */
    this._detectorRunId = detectorRunId;
    /** @type {boolean} */
    this._isTotalSignal = isTotalSignal;
    /** @type {string} */
    this._substanceId = substanceId;
    /** @type {MassRange} */
    this._massRange = massRange;
    /** @type {Wavelength} */
    this._wavelength = wl;
    /** @type {string} */
    this._wavelengthColor = wlColor;
    /** @type {FloatPoint[]} */
    this._baselineAnchors = baselineAnchors;
    this._signalRange = signalRange;
    this._name = name;
    this._eicExtractionProps = eicExtractionProps;
  }

  /** @return {string} */
  getId() {return this._chromatogramId}
  /** @return {string} */
  getDomainId() {return this._domainBlobId}
  /** @return {string} */
  getSignalId() {return this._signaBloblId}
  /** @return {FloatPoint[]} */
  getBaselineAnchors() {return this._baselineAnchors}
  /** @param {FloatPoint[]} anchors */
  setBaselineAnchors(anchors) {this._baselineAnchors = anchors}
  /** @return {string} */
  getDetectorRunId() {return this._detectorRunId}
  /** @return {boolean} */
  isTotalSignal() {return this._isTotalSignal}
  /** @return {string} */
  getSubstanceId() {return this._substanceId}
  /** @return {MassRange} */
  getMassRange() {return this._massRange}
  /** @return {Wavelength} */
  getWl() {return this._wavelength}
  /** @return {string} */
  getWlColor() {return this._wavelengthColor}
  /** @return {Range} */
  getSignalRange() {return this._signalRange}

  getName(){
    return this._name
  }

  /** @returns {IntegrationScope} */
  getScope(){
    return new IntegrationScope(this._name)
  }
  /**
   * @returns {EicExtractionProps}
   */
  getEicExtractionProps(){
    return this._eicExtractionProps
  }

  static parseServerJson(json){
    const massRange = MassRange.parseServerJson(json.massRange);
    const wl = Wavelength.parseServerJson(json.wavelength);
    const baselineAnchors = floatPointsFromBase64String(json.base64EncodedAnchors);
    const signalRange = new Range(json.minSignalIntensity, json.maxSignalIntensity);
    const eicExtractionProps = json.eicExtractionProps ?
        EicExtractionProps.parseServerJson(json.eicExtractionProps) : null;
    return new ChromatogramJson(json.chromatogramId, json.domainId, json.signalId, json.detectorId,
      json.totalSignal, json.substanceId, massRange, wl, json.wavelengthColor, baselineAnchors, signalRange, json.name,
        eicExtractionProps)
  }
}

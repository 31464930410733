export default class PlateMeta {
    /**
     * @param {string} id
     * @param {number} rows
     * @param {number} cols
     */
    constructor(id, rows, cols) {
        this._id = id;
        this._rows = rows;
        this._cols = cols;
    }

    /** @return {string} */
    get id() {
        return this._id;
    }

    /** @return {number} */
    get rows() {
        return this._rows;
    }

    /** @return {number} */
    get cols() {
        return this._cols;
    }

    /**
     * @param json
     * @return {PlateMeta}
     */
    static parseServerJson(json) {
        return new PlateMeta(json.id, json.rows, json.columns);
    }
}

export default class PeakRow {

  constructor(peak, injection, chromatogram) {
    /** @type {PivotedInjection} */
    this.injection = injection;
    /** @type {PivotedPeak} */
    this.peak = peak;
    /**@type {PivotedSubstance}*/
    this.substance = peak.substance || {};
    /** @type {PivotedChromatogram} */
    this.chromatogram = chromatogram;
  }
}
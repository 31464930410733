export default class HtmlElements {

    static generateReportDialog = function (batch) {
        return `
  <div class="dialog-container" id="export-dialog">
    <div class="dialog-title-container">
      <p>Export batch</p>
    </div>
    <div class="dialog-content-container">
      <p>Export ${batch.name} to excel?</p>
    </div>
    <div class="dialog-actions-container">
      <button class="flat-button btn-cancel">cancel</button>
      <button class="flat-button btn-ok">export</button>
    </div>
  </div>`
    };

    static iconByName = function (name, className) {
        if (name === 'arrows')
            return `<svg width="24" height="36" class="reversible">
              <path d="M8 15 l3 3 -3 3 M13 15 l3 3 -3 3" fill="none" fill-rule="evenodd" stroke="currentColor"
                    stroke-linecap="round" stroke-width="2"/>
            </svg>`
        if (name === 'plus')
            // We use outlined icon when have substance on injection page and contained icon otherwise
            return `<svg class="substance-bar__add-icon substance-bar__add-icon--outlined" width="24" height="36" viewBox="-4 -10 24 36">
              <path fill="currentColor" d="M8 1c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7zm0-1C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"/>
              <path fill="currentColor" d="M13 7H9V3H7v4H3v2h4v4h2V9h4z"/>
            </svg>
            <svg class="substance-bar__add-icon substance-bar__add-icon--contained" height="36" viewBox="0 0 32 32" width="18.3">
                <path fill="currentColor" d="M16,2A14.1725,14.1725,0,0,0,2,16,14.1725,14.1725,0,0,0,16,30,14.1725,14.1725,0,0,0,30,16,14.1725,14.1725,0,0,0,16,2Zm8,15H17v7H15V17H8V15h7V8h2v7h7Z" transform="translate(0 0)"/>
            </svg>`
        if (name === 'upload')
            return `<svg xmlns="http://www.w3.org/2000/svg" width="128px" height="128px" viewBox="0 0 128 128" class="${className}">
          			  			<path 	d="M91.215,90H77c-0.553,0-1-0.447-1-1s0.447-1,1-1h14.215C103.431,88,113,78.684,113, 66.79c0-11.908-9.772-21.596-21.785-21.596c-1.498,0-3.062,0.188-4.785,0.575c-0.418, 0.094-0.842-0.086-1.066-0.443c-5.187-8.244-14.08-13.166-23.789-13.166c-13.957,0-25.628, 10.019-27.751, 23.823c-0.083,0.542-0.584,0.928-1.132, 0.837c-0.82-0.119-1.551-0.177-2.235-0.177C21.789,56.644,15,63.323,15,71.852C15,80.604,22.078, 88,30.456,88H52c0.553,0,1,0.447,1,1s-0.447,1-1,1H30.456C20.994,90,13,81.689,13,71.852c0-9.649, 7.668-17.208,17.456-17.208c0.496,0,1.01,0.026,1.553,0.079C34.664,40.432,46.947,30.16, 61.574, 30.16c10.167,0,19.499,5.038,25.103,13.513c1.604-0.321,3.095-0.479,4.538-0.479C104.33,43.194,115, 53.779,115,66.79C115,79.805,104.553,90,91.215,90z"
          			  	 				fill="#323638"/>
              		  			<path 	d="M64.589,97.825c-0.553,0-1-0.447-1-1V62.223L51.992,74.835c-0.374,0.408-1.006,0.434-1.413, 0.06c-0.406-0.374-0.434-1.007-0.06-1.413l13.333-14.5c0.279-0.304,0.716-0.404,1.1-0.255s0.637,0.52,0.637, 0.932v37.167C65.589,97.378,65.142,97.825,64.589,97.825z"
              		  	 				fill="#323638"/>
              		  	 		<path 	d="M77.922,75.325c-0.271,0-0.543-0.11-0.74-0.327L63.849,60.331c-0.371-0.409-0.341-1.041, 0.067-1.413c0.408-0.37,1.042-0.341,1.413,0.067l13.333,14.667c0.371,0.409,0.341,1.041-0.067, 1.413C78.403,75.239,78.162,75.325,77.922,75.325z"
              		  	 				 fill="#323638"/>
              				</svg>`
    }
}

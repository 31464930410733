'use strict'
import OnPlatePosition from '../../components/plate/OnPlatePosition.js';
import {formattedDateFromMillis} from '../../util/CommonUtils.js'
import AccessLevel from "../../entities/AccessLevel.js";

export default class PivotedInjection {
    /**
     * @param {{
     *  id:string,
     *  name:string,
     *  pos:OnPlatePosition,
     *  inletMethod: string,
     *  detectorMethod: string,
     *  instrumentName: string,
     *  duration: string,
     *  timestamp: number,
     *  run: string,
     *  plateId:string
     *  userAccessLevel: AccessLevel
     *  }} opts
     */
    constructor(opts) {
        this._id = opts.id;
        this._name = opts.name || '';
        this._pos = opts.pos || new OnPlatePosition(0,0) ;
        this._inletMethod = opts.inletMethod || '';
        this._detectorMethod = opts.detectorMethod || '';
        this._instrumentName = opts.instrumentName || '';
        this._duration = opts.duration || 0;
        this._acquiredTime = formattedDateFromMillis(opts.timestamp);
        this._timestamp = opts.timestamp;
        this._plateId = opts.plateId;
        this._run = opts.run;
        this._userAccessLevel = opts.userAccessLevel;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get pos() {
        return this._pos;
    }

    get well() {
        return this._pos.toUserString();
    }

    get inletMethod() {
        return this._inletMethod;
    }

    get detectorMethod() {
        return this._detectorMethod;
    }

    get instrumentName() {
        return this._instrumentName;
    }

    get duration() {
        return this._duration;
    }

    get acquiredTime() {
        return this._acquiredTime;
    }

    get timestamp() {
        return this._timestamp;
    }

    get plateId() {
        return this._plateId;
    }

    /**
     * @returns {string} - this property is used to group injections by job-id, but for simplicity
     * we call it run.
     */
    get run() {
        return this._run;
    }
    /**
     * @returns {AccessLevel}
     */
    get userAccessLevel() {
        return this._userAccessLevel;
    }
    static parseServerJson(j){
        const opts = {};
        opts.id = j.i;
        opts.name = j.n;
        opts.plateId = j.p;
        opts.pos = new OnPlatePosition(j.r, j.c);
        opts.inletMethod = j.im;
        opts.detectorMethod = j.dm;
        opts.instrumentName = j.in;
        opts.timestamp = +j.a;
        opts.duration = +j.d;
        opts.run = j.j;
        opts.userAccessLevel = new AccessLevel(+j.ual);
        return new PivotedInjection(opts);
    }
}
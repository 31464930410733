export default class OperationNotAllowedError extends Error {
    #operationType;

    constructor(operationType, message) {
        super(message);
        this.#operationType = operationType;
    }

    get operationType() {
        return this.#operationType;
    }
}
import {getOrgIcon} from "../../js/util/OrgUtils.js";
import {safeText} from "../../js/util/CommonUtils.js";
import {createOrgNameUrlTemplate} from "../../js/util/UrlUtils.js";
import {getUserIcon} from "../../js/util/UserUtils.js";

export default class HomePageHtml {
	/** @type {HTMLElement} */
	#rootEl;
	/** @type {SecurityContext} */
	#securityContext;
	/** @type {HTMLElement} */
	#activeImg;
	/** @type {number} */
	#clickInterval;
	#onCreateOrgClickCb = ()=>{};
	#addInjectionClickCb = ()=>{}

	/**
	 * @param {Element} rootEl
	 * @param {SecurityContext} securityContext
	 */
	init(rootEl, securityContext) {
		this.#rootEl = rootEl;
		this.#securityContext = securityContext;
	}

	/** @param {string[]} news */
	show(news) {
		this.#rootEl.innerHTML = this.#htmlTemplate(news);
		this.#addEventListeners();
		this.#startAutoSwitch();
	}

	/** @param {function} cb */
	createOrgClickCb (cb){
		this.#onCreateOrgClickCb = cb;
	}

	/** @param {function} cb */
	addInjectionClickCb (cb){
		this.#addInjectionClickCb = cb;
	}

	stopAutoSwitch(){
		clearInterval(this.#clickInterval);
	}

	#onCreateOrgClick(){
		this.#onCreateOrgClickCb()
	}

	#addInjectionClick(){
		this.#addInjectionClickCb()
	}

	/** @returns {HTMLElement} */
	#getCreateOrgButton(){
		return this.#rootEl.querySelector('[data-action="create-org"]')
	}

	/** @returns {HTMLElement} */
	#getAddInjectionButton(){
		return this.#rootEl.querySelector('[data-action="add-injection"]')
	}

	/** @returns {HTMLElement[]} */
	#getSlideImages(){
		return this.#rootEl.querySelectorAll('.slides__item')
	}

	/** @returns {boolean} */
	#isIntroSectionShown(){
		return !this.#securityContext.isAuthenticated() || ( this.#securityContext.getCurrentUser().isVirgin
			&& !this.#securityContext.getCurrentUser().orgs.length);
	}

	#startAutoSwitch() {
		if (!this.#isIntroSectionShown())
			return
		if (!this.#activeImg)
			this.#activeImg = this.#getSlideImages()[0]
		// Clear current interval, when user click on image
		clearInterval(this.#clickInterval);
		this.#clickInterval = setInterval(()=> {
			this.#activeImg.click()
		}, 5000);
	}

	#addEventListeners(){
		const createOrgBtn = this.#getCreateOrgButton();
		if (createOrgBtn)
			createOrgBtn.addEventListener('click', this.#onCreateOrgClick.bind(this));
		const addInjectionBtn = this.#getAddInjectionButton();
		if (addInjectionBtn)
			addInjectionBtn.addEventListener('click', this.#addInjectionClick.bind(this))
		const items = this.#getSlideImages();
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			item.addEventListener('click', ()=>{
				// Save next (or first) item as active
				this.#activeImg = i < items.length - 1 ? items[i + 1] : items[0];
				for (let it of items)
					it.style.transform = `translateX(-${((i+1) % items.length)*100}%)`;
				this.#startAutoSwitch()
			})
		}
	}

	/** @returns {string} */
	#introSectionTemplate(){
		if (!this.#isIntroSectionShown())
			return ''

		return `<section class="home-page__intro-section">
		          <h1 class="home-page__title">All-in-one solution for LC/GC data</h1>
                  <h2 class="home-page__subtitle">
                    <span class="highlight-container highlight-container--yellow"> <p class="highlight">Process</p></span>,
                    <span class="highlight-container highlight-container--magenta"> <p class="highlight">store</p></span> and 
                    <span class="highlight-container highlight-container--green"> <p class="highlight">collaborate</p></span> 
                    on your chromatographic data</h2>
			   </section>
			   <div class="home-page__action-cards">
                  ${this.#uploadInjectionTemplate()}
                  ${this.#createOrgSectionTemplate()}
			   </div>
`
	}

	/** @returns {string} */
	#heroSectionTemplate(){
		if (!this.#isIntroSectionShown())
			return ''

		return `<section class="hero">
                	<div class="slider">
                		<div class="slides">
                			<div class="slides__item slides__item--1">
                				<h2 class="home-page__card-title">Process chromatograms</h2>
                				<img class="slides__image" src="/static/img/home-chroms.png" alt="photo"/>
                			</div>
                			<div class="slides__item slides__item--2">
                				<h2 class="home-page__card-title">Analyze data</h2>
                				<img class="slides__image" src="/static/img/home-table.png" alt="photo"/>
                			</div>
                			<div class="slides__item slides__item--3">
                				<h2 class="home-page__card-title">Visualize results</h2>
                				<img class="slides__image" src="/static/img/home-viz.png" alt="photo"/>
                			</div>
                			<div class="slides__item slides__item--4">
                				<h2 class="home-page__card-title">Collaborate securely</h2>
                				<img class="slides__image" src="/static/img/home-collab.svg" alt="photo"/>
                			</div>
						</div>
					</div>
                </section>
		`
	}

	/**
	 * @param {Org} org
	 * @returns {string}
	 */
	#getOrgLinkTemplate(org){
		return `
			<div class="home-page__org-row">
				<a class="home-page__org-link" href="${createOrgNameUrlTemplate(org.name)}">
					${getOrgIcon(org)}
					<div class="home-page__org-name" title="${safeText(org.displayName)}">${safeText(org.displayName)}</div>
				</a>
			</div>
		`
	}

	/**
	 * @param {string[]} htmlNewsTemplate
	 * @returns {string}
	 */
	#newsSectionTemplate(htmlNewsTemplate){
		if(!htmlNewsTemplate.length || this.#isIntroSectionShown())
			return ""

		return `
			<section class="home-page__news-section">
				${htmlNewsTemplate.map(item => this.#newsItemTemplate(item)).join('')}
			</section>
		`
	}

	#newsItemTemplate(html){
		return `<div class="home-page__card--news">${html}</div>`
	}

	/** @returns {string} */
	#createOrgSectionTemplate(){
		const user = this.#securityContext.getCurrentUser();
		if (user && !user.capabilities.canCreateOrgs)
			return ''

		return `
			<div class="home-page__card home-page__card--action home-page__create-org-card">
				<div class="home-page__card-body">
					<button data-action="create-org" class="button button--contained home-page__card-button">Create organization</button>
					<p class="home-page__card-description">Create teams, share, collaborate securely</p>
				</div>
			</div>
		`
	}

	/** @returns {string} */
	#uploadInjectionTemplate(){
		return `
			<div class="home-page__card home-page__card--action home-page__upload-injection-card">
				<div class="home-page__card-body">
					<button data-action="add-injection" class="button button--contained home-page__card-button">Upload injection</button>
					<p class="home-page__card-description">Once uploaded, it will be visible only to you by default</p>
					<p class="home-page__card-description">Explore how <a href="https://peaksel.elsci.io/a/elsci/injection/8eAUzXtztut">public data</a> looks</p>
				</div>
			</div>
		`
	}

	/** @returns {string} */
	#yourMenuSectionTemplate(){
		if(this.#isIntroSectionShown())
			return ''

		const addOrgIcon = this.#securityContext.getCurrentUser().capabilities.canCreateOrgs ?
			'<span data-action="create-org" class="home-page__org-block-title-icon material-symbols-outlined">add_circle</span>' : "";

		return `
		<section class="home-page__your-menu-section home-page__org-column">
			<div class="home-page__orgs">
				<div class="home-page__your-menu-title">
					<span class="home-page__org-block-title">Your organizations</span>
					${addOrgIcon}
				</div>
				<div class="home-page__org-names-block">
					<div class="home-page__org-row">
						<a class="home-page__org-link" href="${createOrgNameUrlTemplate(this.#securityContext.getCurrentUser().basicUser.name)}">
							${getUserIcon(this.#securityContext.getCurrentUser().basicUser)}
							<div class="home-page__org-name">Your data</div>
						</a>
					</div>
					${this.#securityContext.getCurrentUser().orgs.map(org => this.#getOrgLinkTemplate(org)).join('')}
				</div>
			</div>
		</section>`
	}

	/**
	 * @param {string[]} news
	 * @returns {string}
	 */
	#htmlTemplate(news) {
		return `
			<div class="home-page__container">
                ${this.#yourMenuSectionTemplate()}
                <div class="home-page__main-column">
                	<div class="home-page__main-column-body">
                		${this.#introSectionTemplate()}
                		${this.#heroSectionTemplate()}
                		${this.#newsSectionTemplate(news)}
					</div>
                </div>
            </div>`;
	}
}

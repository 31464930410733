export default class IntegrationScope {
    constructor(name) {
        this._name = name;
    }

    getName(){
        return this._name
    }
    getAnalyticalMethod() {
        const pos = this._name.indexOf(' ');
        if (pos !== -1)
            return this._name.substring(0, pos);
        else
            return this._name;
    }

    getDisplayName(){
        return this._name;
    }

    isDefault() {
        return this.getAnalyticalMethod() === this._name;
    }

    equals(that) {
        if(that === this)
            return true;
        if(!that)
            return false;
        if(that.constructor.name !== this.constructor.name)
            return false;
        return this._name === that._name;
    }
}

export default class SubstanceJson{
  /**
   * @param {string?} id
   * @param {string?} alias
   * @param {string|null?} mf
   * @param {number|null?} emw
   * @param {string?} structureId
   * @param {string?} color
   * @param {string?} structureSvgPath
   */
  constructor({id, alias, mf, emw, structureId, color,
                structureSvgPath} = {}) {
    this._id = id;
    this._alias = alias;
    this._mf = mf;
    this._emw = emw;
    this._structureId = structureId;
    this._savedColor = color;
    this._displayColor = null;
    this._structureSvgPath = structureSvgPath;
  }

  /** @return {string} */
  getId() {return this._id}
  /** @return {string} */
  getSavedColor() {return this._savedColor}

  /** @returns {*|string} */
  getDisplayColor(){
    const color = this._savedColor ? this._savedColor : this._displayColor;
    if (!color)
      throw new Error(`Substance color is null as well as display_color,
       probably substance was not passed into SubstanceFiller. Substance: ${this.toServerJson()}`);
    return color;
  }

  /** @param {string} color */
  setDisplayColor(color){
    this._displayColor = color;
  }

  /** @return {string} */
  getAlias() {return this._alias}
  /** @return {string|null} */
  getMolecularFormula() {return this._mf}
  /** @return {string} */
  getDisplayName() {
    return this.getAlias() ? this.getAlias()
        : this.getMolecularFormula() ? this.getMolecularFormula()
            : this.getEmwDisplayName();
  }
  /** @return {string} */
  getStructureId(){return this._structureId}
  /** @param {string} alias */
  setAlias(alias){this._alias = alias}
  /** @return {number} */
  getMonoIsotopicMass(){return this._emw}
  getEmwDisplayName(){return SubstanceJson.getEmwDisplayName(this.getMonoIsotopicMass())}

  /** @return {string|null} */
  getStructureSvgPath() {
    return this._structureSvgPath
  }
  hasSameComposition(that) {
    if (!this.getMolecularFormula() && !that.getMolecularFormula())
      return this.getMonoIsotopicMass() === that.getMonoIsotopicMass();
    return this.getMolecularFormula() === that.getMolecularFormula()
        && this.getStructureId() === that.getStructureId();
  }

  toServerJson() {
    return {
      id: this.getId(),
      alias: this.getAlias(),
      mf: this.getMolecularFormula(),
      emw: this.getMonoIsotopicMass(),
      structureId: this.getStructureId(),
      color: this.getSavedColor()
    }
  }

  equals(that) {
    if (this === that) return true;
    if (!(that instanceof SubstanceJson)) return false;
    return this.getId() === that.getId()
        && this.getAlias() === that.getAlias()
        && this.getMolecularFormula() === that.getMolecularFormula()
        && this.getMonoIsotopicMass() === that.getMonoIsotopicMass()
        && this.getStructureId() === that.getStructureId()
        && this.getSavedColor() === that.getSavedColor();
  }

  /** @param {SubstanceJson} update */
  put(update) {
    // Any field can be updated except id, moreover, if id is updated, it means that it is a new substance
    // and this method should not be used
    if (update.getId() !== this.getId())
      throw new Error(`Substance id is different from the original one: ${this.getId()} vs ${update.getId()}`);
    this._alias = update.getAlias();
    this._mf = update.getMolecularFormula();
    this._emw = update.getMonoIsotopicMass();
    this._structureId = update.getStructureId();
    this._savedColor = update.getSavedColor();
    this._displayColor = update.getDisplayColor();
    this._structureSvgPath = update.getStructureSvgPath();
  }

/**
   * @param json
   * @return {SubstanceJson}
   */
  static parseServerJson(json){
    return new SubstanceJson(json)
  }

  /**
   * @returns {string|null} - emw value rounded to the 2 digits after the decimal point
   */
  static getEmwDisplayName(emw) {
    return emw ? emw.toFixed(2) : null;
  }
}

export default class Range {
  /**
   * @param {number} lower
   * @param {number} upper
   */
  constructor(lower, upper) {
    this.lower = lower;
    this.upper = upper;
  }

  /** @return {boolean} */
  overlaps(another) {
    return this.includesExclusively(another.lower) || this.includesExclusively(another.upper) || this.equals(another)
  }

  /** @returns {Range} */
  merge(anotherRange){
    return new Range(Math.min(this.lower, anotherRange.lower), Math.max(this.upper, anotherRange.upper))
  }

  /**
   * @param {Range} that
   * @return {Range}
   */
  intersection(that) {
    const lower = Math.max(this.lower, that.lower);
    const upper = Math.min(this.upper, that.upper);
    return new Range(lower, upper);
  }

  /** @return {boolean} */
  contains(value) {
    return this.lower <= value && value <= this.upper
  }

  /** @return {boolean} */
  containsAll(...values) {
    for (let value of values) {
      if (!this.contains(value)) return false
    }
    return true
  }

  /** @return {boolean} */
  isEmpty() {
    return this.lower < 0 || this.upper < 0 || this.lower === this.upper
  }

  /** @return {boolean} */
  includesExclusively(value) {
    return this.lower < value && this.upper > value
  }

  /** @return {boolean} */
  equals(another) {
    if(!another)
      return false;
    return this.lower === another.lower && this.upper === another.upper
  }

  /** @return {number} */
  width() {
    return Math.abs(this.upper - this.lower)
  }

  /**
   * @return {number[]}
   */
  asArray(){
    return [this.lower, this.upper];
  }

  /**
   * @param scaleFn
   * @returns {Range}
   */
  scale(scaleFn){
    return new Range(scaleFn(this.lower), scaleFn(this.upper))
  }

  /** @return {Range} */
  static parseServerJson(data) {
    return new Range(+data.lower.toFixed(6), +data.upper.toFixed(6));
  }
    toString() {
        return `Range(${this.lower}, ${this.upper})`;
    }
}

export default class Team{
    /** @type {string} */
    #id;
    /** @type {string} */
    #name;
    /** @type {string|null} */
    #orgId;
    /** @type {string} */
    #description

    /**
     * @param {string} id
     * @param {string} name
     * @param {string|null} orgId
     * @param {string} description
     */
    constructor(id, name, orgId, description) {
        this.#id = id;
        this.#name = name;
        this.#orgId = orgId;
        this.#description = description;
    }

    /** @returns {string} */
    get id(){
        return this.#id
    }

    /** @returns {string} */
    get name(){
        return this.#name
    }

    /** @returns {string} */
    get description(){
        return this.#description
    }

    /** @returns {boolean} */
    isGlobalAdminTeam() {
        return this.#id === Team.teamIdentifiers.GLOBAL_ADMIN_ID;
    }

    /** @returns {boolean} */
    isAdminTeam() {
        return this.#name === Team.teamIdentifiers.ADMIN_NAME && !this.isGlobalAdminTeam();
    }

    isPublicTeam(){
        return this.#id === Team.teamIdentifiers.PUBLIC_ID
    }

    /** @returns {Team} */
    createCopy(){
        return new Team(this.#id, this.#name, null, null)
    }

    /** @returns {Team} */
    static parseServerJson(json) {
        return new Team(json.id, json.name, json.orgId, json.description);
    }

    /**
     * @param {{}[]} teamsJson
     * @returns {Team[]}
     */
    static parseServerJsonMany(teamsJson) {
        const result = [];
        teamsJson.forEach(json => result.push(Team.parseServerJson(json)));
        return result
    }

    static teamIdentifiers = Object.freeze({
        "GLOBAL_ADMIN_ID": "a",
        "ADMIN_NAME": "Admins",
        "PUBLIC_ID": "p",
        "PUBLIC_NAME": "Public",
    });

    static createPublicTeam(){
        return new Team(Team.teamIdentifiers.PUBLIC_ID, Team.teamIdentifiers.PUBLIC_NAME, null, "")
    }

    static TITLE_FOR_ACTION = {
        CANNOT_DELETE_HIMSELF_FROM_ADMINS: "You can't remove yourself from the Admins team, only other Admins can remove you",
        CANNOT_DELETE_HIMSELF_FROM_ORG: "You can't remove yourself from organization, only other admins can remove you",
        CANNOT_DELETE_ADMINS: "You don't have sufficient permissions",
    }
}
import UserHeaderHtml from "./UserHeaderHtml.js";
import UserMenuCmp from "../UserMenu/UserMenuCmp.js";

export default class UserHeaderCmp {
    /** @type {HTMLElement} */
    #rootEl;
    /** @type {UserHeaderHtml} */
    #html = new UserHeaderHtml();
    /** @type {OrgRepository}  */
    #orgRepo;
    /** @type {UserMenuCmp} */
    #userMenu = new UserMenuCmp();

    /** @param {OrgRepository} orgRepo */
    constructor(orgRepo) {
        this.#orgRepo = orgRepo
    }

    /**
     * @param {HTMLElement} rootEl
     */
    init(rootEl) {
        this.#rootEl = rootEl;
        this.#html.init(this.#rootEl);
    }

    /** @param {UserWithOrgs} user */
    show(user) {
        this.#html.show(user);
        this.#userMenu.init(this.#rootEl, user, this.#orgRepo);
    }
}
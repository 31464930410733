import AliasColor from "./AliasColor.js";

export default class AliasColors {
	/**
	 * @param { string[] } aliases
	 * @param { string[] } colors
	 */
	constructor(aliases, colors) {
		if (!Array.isArray(aliases) || !Array.isArray(colors) || aliases.length !== colors.length)
			throw new Error("Can't create AliasColors");
		this._aliasColors = new Map();
		for (let i = 0; i < aliases.length; i++)
			this._aliasColors.set(aliases[i], colors[i])
	}

	/**
	 * @param { string } alias
	 * @param { string } color
	 */
	set(alias, color) {
		this._aliasColors.set(alias, color);
	}

	/**
	 * @param { string } alias
	 */
	delete(alias) {
		this._aliasColors.delete(alias);
	}

	/**
	 * @return { AliasColor[] }
	 */
	asArray() {
		const arr = [];
		for (const [key, value] of this._aliasColors.entries())
			arr.push(new AliasColor(key, value))
		return arr;
	}

	static parseServerString(serverString) {
		const aliases = [];
		const colors = [];
		const elements = serverString.split('\t');
		for (let i = 0; i < elements.length - 1; i+=2) {
			aliases.push(elements[i]);
			colors.push(elements[i+1]);
		}
		return new AliasColors(aliases, colors);
	}

	toServerString() {
		const entries = [];
		for (const [key, value] of this._aliasColors.entries())
			entries.push(key, value);
		return entries.join('\t');
	}
}
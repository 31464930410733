import HomePageHtml from "./HomePageHtml.js";
import CreateOrgDialogCmp from "../../components/CreateOrgDialog/CreateOrgDialogCmp.js";
import {addParamToURL, removeParamFromURL} from "../../js/util/UrlUtils.js";
import HtmlUtils from "../../components/HtmlUtils.js";
import AddInjectionDialogCmp from "../../components/AddInjectionDialog/AddInjectionDialogCmp.js";
import Org from "../../js/entities/organization/Org.js";
import HttpError from "../../js/entities/error/HttpError.js";

export default class HomePageCmp {
	/** @type {HomePageHtml} */
	#html= new HomePageHtml();
	/** @type {HTMLElement} */
	#rootElement;
	/** @type {HomePageHeaderCmp} */
	#pageHeader;
	/** @type {SecurityContext} */
	#securityContext;
	/** @type {OrgRepository} */
	#orgRepo;
	/** @type {GlobalSettingsRepository} */
	#globalSettingsRepo;
	#createOrgDialog = new CreateOrgDialogCmp();
	/** @type {AddInjectionDialogCmp} */
	#addInjectionDialog;

	/**
	 * @param {SecurityContext} securityContext
	 * @param {HomePageIoC} ioc
	 */
	constructor(securityContext, ioc){
		this.#securityContext = securityContext;
		this.#pageHeader = ioc.getPageHeaderCmp();
		this.#globalSettingsRepo = ioc.getGlobalSettingsRepository();
		this.#orgRepo = ioc.getOrganizationRepository();
		this.#addInjectionDialog = new AddInjectionDialogCmp(ioc.getInjectionRepository(),
			ioc.getGlobalSettingsRepository(), ioc.getBatchRepository(), this.#createPersonalOrg(), this.#securityContext)
	}

	/** @param {HTMLElement} rootElement */
	init(rootElement) {
		this.#rootElement = rootElement;
		this.#html.init(rootElement, this.#securityContext);
		this.#pageHeader.init(document.querySelector('.page-header'));
		this.#pageHeader.setSecurityContext(this.#securityContext);
		this.#html.createOrgClickCb(this.#onCreateOrgClick.bind(this));
		this.#html.addInjectionClickCb(this.#onAddInjectionClick.bind(this));
		this.#createOrgDialog.onSave(this.#createOrganization.bind(this));
		this.#createOrgDialog.onCancel(this.#removeDialogParamFromUrl.bind(this));
		this.#addInjectionDialog.onCancel(this.#removeDialogParamFromUrl.bind(this));
	}

	async show() {
		let news;
		try {
			news = await this.#getNews();
		} catch (err) {
			if (err instanceof HttpError && err.isNotFound){
				news = []
			} else {
				throw err
			}
		}
		this.#html.show(news);
		this.#openDialogIfUrlHasParam();
	}

	stopAutoSwitch(){
		this.#html.stopAutoSwitch()
	}

	/** @returns {Org} */
	#createPersonalOrg(){
		if (!this.#securityContext.isAuthenticated())
			return Org.empty()
		return Org.createPersonalOrg(this.#securityContext.getCurrentUser().basicUser)
	}

	#openDialogIfUrlHasParam(){
		const url = new URL(window.location.href);
		const dialogName = url.searchParams.get('dialog');
		if (!dialogName)
			return
		if (this.#securityContext.isAuthenticated()) {
			if (dialogName === HomePageCmp.DIALOG_NAME.CREATE_ORG
				&& this.#securityContext.getCurrentUser().capabilities.canCreateOrgs) {
				this.#createOrgDialog.show()
			} else if (dialogName === HomePageCmp.DIALOG_NAME.UPLOAD_INJECTION) {
				this.#addInjectionDialog.show()
			} else {
				this.#removeDialogParamFromUrl()
			}
		} else {
			this.#removeDialogParamFromUrl()
		}
	}

	async #getNews(){
		return await this.#globalSettingsRepo.getNews();
	}

	#removeDialogParamFromUrl(){
		removeParamFromURL('dialog')
	}

	#onCreateOrgClick(){
		addParamToURL('dialog', HomePageCmp.DIALOG_NAME.CREATE_ORG);
		if (!this.#securityContext.isAuthenticated()) {
			window.location.href = this.#securityContext.signInUrl;
			return
		}
		this.#createOrgDialog.show();
	}

	#onAddInjectionClick(){
		addParamToURL('dialog', HomePageCmp.DIALOG_NAME.UPLOAD_INJECTION);
		if (!this.#securityContext.isAuthenticated()) {
			window.location.href = this.#securityContext.signInUrl;
			return;
		}
		this.#addInjectionDialog.show();
	}

	/** @param {string} name */
	async #createOrganization(name) {
		try {
			const resp = await this.#orgRepo.createOrganization(name);
			this.#createOrgDialog.remove();
			window.location.href = `${window.location.origin}/a/${resp.name}`
		} catch (error) {
			if (error.isBadRequest)
				this.#createOrgDialog.showInputErrors(error.response.errors)
			else throw error
		}
	}

	static DIALOG_NAME = {
		UPLOAD_INJECTION: "upload-injection",
		CREATE_ORG: "create-organization"
	}
}
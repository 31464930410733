import NotificationDialogHtml from './NotificationDialogHtml.js';
import {safeText} from '../../js/util/CommonUtils.js'

export default class NotificationDialogCmp {
    constructor() {
        this._html = new NotificationDialogHtml();
    }

    show(title, text) {
        this._html.show(safeText(title), safeText(text));
    }
    showUnsafe(title, text) {
        this._html.show(title, text);
    }

    isShown() {
        return this._html.isShown();
    }

    onOk(callback) {
        this._html.onOk(callback);
    }

    onClose(callback){
        this._html.onClose(callback)
    }
}
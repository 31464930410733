import BaseDialogCmp from "../../../components/BaseDialog/BaseDialogCmp.js";

export default class PermissionOnResourceErrorHandler {
    /** @type {BaseDialogCmp} */
    #refreshPopup;

    constructor() {
        this.#refreshPopup = new BaseDialogCmp({btnCancel: "Close", btnOk: "Refresh"});
    }

    /** @param {PermissionOnResourceError} error */
    handle(error) {
        if (this.#refreshPopup.isAlreadyShown())
            return
        this.#refreshPopup.onOk(() => {
            location.reload()
        });
        this.#refreshPopup.showText(error.errorMessage)
    }
}
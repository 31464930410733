import BatchListEntry from "../entities/BatchListEntry.js";
import BatchMeta from "../entities/BatchMeta.js";
import FormValidationError from "../entities/error/FormValidationError.js";
import VisualizationData from "../components/visualization/VisualizationData.js";
import InjectionMetaJson from "../entities/InjectionMetaJson.js";
import HttpError from "../entities/error/HttpError.js";

export default class BatchRepository {
	#httpClient;
	#endpointUrl;

	/**
	 * @param {HttpClient} httpClient
	 * @param {string} endpointUrl
	 */
	constructor(httpClient, endpointUrl = '/api/batch') {
		this.#httpClient = httpClient;
		this.#endpointUrl = endpointUrl;
	}

	async getBatchInjections(batchId) {
		const response = await this.#httpClient
			.fetch(`${this.#endpointUrl}/${encodeURIComponent(batchId)}/injections`, {
				method: 'GET',
				headers: {
					"Accept": "application/json"
				},
				responseType: 'json'
			});
		const injections = [], injectionsPerPlate = new Map();
		const idToInjectionMap = new Map();
		for (const json of response.injections) {
			const injection = InjectionMetaJson.parseServerJson(json);
			injections.push(injection);
			idToInjectionMap.set(injection.id, injection);
			if (!injectionsPerPlate.has(injection.plateId)) {
				injectionsPerPlate.set(injection.plateId, []);
			}
			injectionsPerPlate.get(injection.plateId).push(injection);
		}
		return {
			id: response.batch.id,
			name: response.batch.name,
			injections: injections,
			map: injectionsPerPlate,
			idToInjectionMap: idToInjectionMap
		}
	}

	/**
	 * @param {string} batchId
	 * @return {Promise<VisualizationData>}
	 */
	async getVisData(batchId) {
		const response = await this.#httpClient
			.fetch(`${this.#endpointUrl}/v2/${encodeURIComponent(batchId)}/injections`, {
				method: 'GET',
				headers: {
					"Accept": "application/json"
				},
				responseType: 'json'
			});
		return VisualizationData.parseServerJson(response);
	}

	/**
	 * @param {string} orgId
	 * @param {string} name
	 * @param {number} page
	 * @returns {Promise<{batches: *, total, isLastPage}>}
	 */
	async getBatchesByName(orgId, name, page = 0) {
		const orgTemplate = orgId.length ? `orgId=${encodeURIComponent(orgId)}&` : "";
		const response = await this.#httpClient
			.fetch(`${this.#endpointUrl}?${orgTemplate}nameStartsWith=${encodeURIComponent(name)}&page=${encodeURIComponent(page)}`, {
				method: 'GET',
				headers: {
					"Accept": "application/json"
				},
				responseType: 'json'
			});
		const list = response.content.map((json) => BatchListEntry.parseServerJson(json));
		return {batches: list, total: response.total, isLastPage: response.last};
	}

	/**
	 * We return BatchMeta only when batchId is not null and user has enough permissions to read this batch,
	 * otherwise - null
	 * @param {string} batchId
	 * @returns {Promise<BatchMeta>}
	 */
	async getMeta(batchId) {
		if (!batchId)
			return null;
		const response = await this.#httpClient
			.fetch(`${this.#endpointUrl}/${encodeURIComponent(batchId)}`, {
				method: 'GET',
				headers: {
					"Accept": "application/json"
				},
				responseType: 'json'
			});
		return BatchMeta.parseServerJson(response);
	}

	async saveVisualizations(batchId, visualisations) {
		await this.#httpClient
			.fetch(`${this.#endpointUrl}/${encodeURIComponent(batchId)}/visualizations`, {
				method: 'PUT',
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(visualisations)
			})
	}

	async addAliasColor(batchId, aliasColor) {
		await this.#httpClient
			.fetch(`${this.#endpointUrl}/${encodeURIComponent(batchId)}/aliasColors`, {
				method: 'POST',
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(aliasColor.toServerJson())
			});
	}

	async editBatchAliasColor(batchId, alias, replacementAC) {
		try {
			await this.#httpClient
				.fetch(`${this.#endpointUrl}/${encodeURIComponent(batchId)}/aliasColors`, {
					method: 'PUT',
					headers: {
						"Content-Type": "application/json",
						"Accept": "application/json"
					},
					responseType: 'json',
					body: JSON.stringify({
						prevAlias: alias,
						newAliasColor: replacementAC.toServerJson()
					})
				});
		} catch (error) {
			if (error instanceof HttpError && error.isBadRequest) {
				const {field, message} = error.response.errors[0];
				throw new FormValidationError(field, message);
			} else {
				throw error;
			}
		}
	}

	/**
	 * @param {string} orgId
	 * @param {string} batchName
	 * @param {string} injectionNameFilter
	 * @returns {Promise<*>}
	 */
	async assignInjectionsToBatch(orgId, batchName, injectionNameFilter) {
		const orgTemplate = orgId.length ? `orgId=${encodeURIComponent(orgId)}&` : "";
		return await this.#httpClient
			.fetch(`${this.#endpointUrl}?${orgTemplate}&batchName=${encodeURIComponent(batchName)}&injectionNameFilter=${encodeURIComponent(injectionNameFilter)}`, {
				method: 'POST',
				responseType: 'text'
			});
	}

	async reassignBatch(injectionIds, batch) {
		const reqBody = {
			batchName: batch.name,
			injections: injectionIds
		};
		if (batch.id)
			reqBody.batchId = batch.id
		return await this.#httpClient
			.fetch(`${this.#endpointUrl}/reassign`, {
				method: 'PUT',
				headers: {
					"Content-Type": "application/json"
				},
				responseType: 'text',
				body: JSON.stringify(reqBody)
			});
	}

	/**
	 * @param {string[]} batchIds
	 * @param {string} action
	 */
	async deleteBatches(batchIds, action){
		return this.#httpClient.fetch(`${this.#endpointUrl}?id=` + `${batchIds.join(',')}&actOnNested=${action}`, {
			method: 'DELETE',
			headers: {
				"Content-Type": "application/json"
			},
			responseType: 'json'
		});
	}

	downloadReport(batchId) {
		window.location = `${this.#endpointUrl}/${encodeURIComponent(batchId)}?format=excel`;
	}

	static ACT_ON_NESTED_METHODS = {
		DELETE: "DELETE",
		UNASSIGN: "UNASSIGN"
	}
}
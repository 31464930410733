'use strict'

export default class PivotedSubstance {
	constructor(alias, mf, emw, stuctureSvgPath) {
		PivotedSubstance._assertAnyNonNull([alias, mf, emw]);
		this.alias = alias;
		this.mf = mf;
		this.emw = emw;
		this.structure = stuctureSvgPath;
	}

	get displayName() {
		return this.alias && this.alias.length ? this.alias : this.mf && this.mf.length ? this.mf : this.emw.toFixed(2);
	}

	static _assertAnyNonNull(args) {
		for (const arg of args)
			if (arg) return
		throw new Error("At least one of following properties (alias, molecularFormula, exactMolecularWeight) must be set");
	}
}
export default class FloatPoint {
  /**
   * @param {number} idx
   * @param {number} val
   */
  constructor(idx, val) {
    this._idx = idx;
    this._val = val;
  }

  /** @return {number} */
  get idx() {return this._idx}
  /** @return {number} */
  get val() {return this._val}

  toServerJson(){
    return {
      value: this.val,
      index: this.idx,
    }
  }
  toString() {
      return `FloatPoint(@${this.idx}=${this.val})`;
  }
}


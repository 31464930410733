import DetectorRunProperties from "../../js/entities/DetectorRunProperties.js";
import {round} from "../../js/util/CommonUtils.js";
import Interval from "../../js/util/Interval.js";

export default class IonConfig {
    /** @type {string} */
    #name;
    /** @type {number} */
    #mz;
    /** @type {number} */
    #calcInt;
    /** @type {number|null} */
    #userInt;
    /** @type {boolean} */
    #ql;
    /** @type {boolean} */
    #qn;

    /**
     * @param {string} name
     * @param {number} mz
     * @param {number} calcInt
     * @param {number|null} userInt
     * @param {boolean} ql
     * @param {boolean} qn
     */
    constructor(name, mz, calcInt, userInt, ql, qn) {
        this.#name = name;
        this.#mz = round(mz, 5);
        this.#calcInt = calcInt;
        this.#userInt = userInt;
        this.#ql = ql;
        this.#qn = qn;
    }

    /** @returns {string} */
    get name() {
        return this.#name;
    }

    /** @returns {number} */
    get mz() {
        return this.#mz;
    }

    /** @returns {number} */
    get intensityPercent() {
        return round(this.intensity * 100);
    }

    /** @param {number} percents */
    set intensityPercent(percents) {
        this.#userInt = round(percents / 100, 2);
    }
    /** @returns {number} */
    get intensity() {
        return this.#userInt !== null ? this.#userInt : this.#calcInt;
    }
    /** @returns {number|null} */
    get userInt() {
        return this.#userInt;
    }

    /** @returns {boolean} */
    get ql() {
        return this.#ql;
    }

    /** @param {boolean} isQualifier */
    set ql(isQualifier) {
        this.#ql = isQualifier;
    }

    /** @returns {boolean} */
    get qn() {
        return this.#qn;
    }

    /** @param {boolean} isQuantifier */
    set qn(isQuantifier) {
        this.#qn = isQuantifier;
    }

    /** @returns {Interval} */
    getMzInterval(massResolution) {
        const lo = Math.max(DetectorRunProperties.PROTON_MASS, this.#mz - massResolution);
        return Interval.closed(lo, this.#mz + massResolution);
    }

    /** @returns {IonConfig} */
    copy() {
        return IonConfig.parseServerJson(this.toServerJson());
    }

    /**
     * @param {IonConfig} other
     * @returns {boolean}
     */
    equals(other) {
        if (this === other) return true;
        if (!(other instanceof IonConfig)) return false;
        return this.#name === other.#name
            && this.#mz === other.#mz
            && this.#calcInt === other.#calcInt
            && this.#userInt === other.#userInt
            && this.#ql === other.#ql
            && this.#qn === other.#qn;
    }

    toServerJson() {
        return {
            name: this.#name,
            mw: this.#mz,
            calcInt: this.#calcInt,
            userInt: this.#userInt,
            ql: this.#ql,
            qn: this.#qn
        }
    }

    /**
     * @param {{}[]} arr
     * @returns {IonConfig[]}
     */
    static parseMany(arr) {
        return arr.map(el => IonConfig.parseServerJson(el));
    }

    /**
     * @param {{}} j
     * @returns {IonConfig}
     */
    static parseServerJson(j) {
        return new IonConfig(j.name, j.mw, j.calcInt, j.userInt, j.ql, j.qn);
    }
}
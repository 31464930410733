export default class Interval {
    /**
     * @type {Bound}
     */
    #lower;
    /**
     * @type {Bound}
     */
    #upper;

    /**
     * @param {Bound} lower
     * @param {Bound} upper
     */
    constructor(lower, upper) {
        this.#lower = lower;
        this.#upper = upper;
    }

    /**
     * @returns {number}
     */
    get lower() {
        return this.#lower.value;
    }
    /**
     * @returns {number}
     */
    get upper() {
        return this.#upper.value;
    }
    /**
     * @returns {Bound}
     */
    lowerBound() {
        return this.#lower;
    }
    /**
     * @returns {Bound}
     */
    upperBound() {
        return this.#upper;
    }
    /**
     * @param {number} value
     * @returns {boolean}
     */
    encloses(value) {
        return (this.lowerBound().lessThan(value) || this.lowerBound().encloses(value))
            && (this.upperBound().greaterThan(value) || this.upperBound().encloses(value));
    }
    /**
     * @param {Interval} another
     * @returns {boolean}
     */
    overlaps(another) {
        return this.encloses(another.lower)
            || this.encloses(another.upper)
            || another.encloses(this.lower)
            || another.encloses(this.upper);
    }
    /**
     * @param {Interval} another
     * @returns {boolean}
     */
    touches(another) {
        return this.lowerBound().touches(another.upperBound())
            || this.upperBound().touches(another.lowerBound());
    }
    /**
     * @param {Interval} another
     * @returns {Interval}
     */
    conjunction(another) {
        if (!this.overlaps(another))
            throw new Error("Cannot get an overlap between " + this +
                " and " + another + " - they don't share values.");
        let lower = this.lower;
        let upper = this.upper;
        if (another.lowerBound().greaterThan(lower))
            lower = another.lower;
        if (another.upperBound().lessThan(upper))
            upper = another.upper;
        return Interval.closed(lower, upper);
    }
    /**
     * @param {number} lower
     * @param {number} upper
     * @returns {Interval}
     */
    static open(lower, upper) {
        return new Interval(Bound.open(lower), Bound.open(upper));
    }
    /**
     * @param {number} lower
     * @param {number} upper
     * @returns {Interval}
     */
    static halfOpenLeft(lower, upper) {
        return new Interval(Bound.open(lower), Bound.closed(upper));
    }
    /**
     * @param {number} lower
     * @param {number} upper
     * @returns {Interval}
     */
    static halfOpenRight(lower, upper) {
        return new Interval(Bound.closed(lower), Bound.open(upper));
    }
    /**
     * @param {number} lower
     * @param {number} upper
     * @returns {Interval}
     */
    static closed(lower, upper) {
        return new Interval(Bound.closed(lower), Bound.closed(upper));
    }
}

class Bound {
    /**
     * @type {number}
     */
    #value;
    /**
     * @type {boolean}
     */
    #closed;

    /**
     * @param {number} value
     * @param {boolean} closed
     */
    constructor(value, closed) {
        this.#value = value;
        this.#closed = closed;
    }

    /**
     * @returns {number}
     */
    get value() {
        return this.#value;
    }
    /**
     * @param {number} value
     * @returns {boolean}
     */
    lessThan(value) {
        return this.value < value;
    }
    /**
     * @param {number} value
     * @returns {boolean}
     */
    encloses(value) {
        return this.#closed && this.value === value;
    }
    /**
     * @param {number} value
     * @returns {boolean}
     */
    greaterThan(value) {
        return value < this.value;
    }
    /**
     * @param {Bound} another
     * @returns {boolean}
     */
    touches(another) {
        return this.encloses(another.value) && another.encloses(this.value);
    }

    /**
     * @param {number} value
     * @returns {Bound}
     */
    static open(value) {
        return new Bound(value, false);
    }
    /**
     * @param {number} value
     * @returns {Bound}
     */
    static closed(value) {
        return new Bound(value, true);
    }
}

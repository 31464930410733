import PivotedInjection from './PivotedInjection.js';
import PivotedChromatogram from './PivotedChromatogram.js';
import PivotedSubstance from './PivotedSubstance.js';
import PivotedPeak from './PivotedPeak.js';
import InjectionRow from './InjectionRow.js';
import PeakRow from './PeakRow.js';

export default class VisualizationData {
    constructor(injectionRows, peakRows) {
        this.injections = injectionRows;
        this.peaks = peakRows;
    }

    /**
     * @param data
     * @return {VisualizationData}
     */
    static parseServerJson(data) {
        const iRows = [], pRows = [];
        for (const datum of data) {
            const pivotedInjection = PivotedInjection.parseServerJson(datum);
            const substances = new Map();
            for (const s of datum.s) {
                substances.set(s.i, new PivotedSubstance(s.a, s.mf, s.em, s.sp))
            }
            const chromatograms = [], peaks = [];
            for (const chromatogram of datum.ch) {
                let name = chromatogram.n;
                if (chromatogram.si)
                    name += ` ${substances.get(chromatogram.si).displayName}`
                const pivotedChromatogram = new PivotedChromatogram(name, chromatogram.area)
                chromatograms.push(pivotedChromatogram);
                const maxPeaks = {};
                for (const peak of chromatogram.p) {
                    const substance = substances.get(peak.si);
                    let pivotedPeak = new PivotedPeak(substance, pivotedChromatogram, peak.a, peak.pot, peak.rt,
                        false, peak.base, peak.s, peak.e, peak.i);
                    peaks.push(pivotedPeak)
                    pRows.push(new PeakRow(pivotedPeak, pivotedInjection, pivotedChromatogram))
                    if (!maxPeaks[peak.si] || maxPeaks[peak.si].area < peak.a)
                        maxPeaks[peak.si] = pivotedPeak;
                }
                for (const key in maxPeaks)
                    maxPeaks[key].largestPeak = true;
            }
            iRows.push(new InjectionRow({
                injection: pivotedInjection,
                peaks: peaks,
                substances: [...substances.values()],
                chromatograms: chromatograms
            }));
        }

        return new VisualizationData(iRows, pRows);
    }
}
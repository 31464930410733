import AccessLevel from "../AccessLevel.js";

export default class Org {
    /** @type {string} */
    #id;
    /** @type {string} */
    #name;
    /** @type {string} */
    #displayName;
    /** @type {AccessLevel} */
    #userAccessLevel;
    /** @type {string} */
    #type;
    /** @type {boolean} */
    #billingAccessible;
    /** @type {boolean} */
    #crawlersAccessible;

    /**
     * @param {string} id
     * @param {string} name
     * @param {string} displayName
     * @param {AccessLevel} userAccessLevel
     * @param {boolean} billingAccessible
     * @param {boolean} crawlersAccessible
     * @param {string} type
     */
    constructor(id, name, displayName, userAccessLevel, type, billingAccessible,
                crawlersAccessible) {
        this.#id = id;
        this.#name = name;
        this.#displayName = displayName;
        this.#userAccessLevel = userAccessLevel;
        this.#type = type;
        this.#billingAccessible = billingAccessible;
        this.#crawlersAccessible = crawlersAccessible;
    }

    /** @returns {string} */
    get id() {
        return this.#id;
    }

    /** @returns {string} */
    get name() {
        return this.#name;
    }

    /** @returns {string} */
    get displayName() {
        return this.#displayName;
    }

    /** @returns {AccessLevel} */
    get userAccessLevel(){
        return this.#userAccessLevel
    }

    /** @returns {boolean} */
    isPersonalOrg(){
        return this.#type === Org.TYPE.PERSONAL;
    }

    /** @returns {boolean} */
    get billingAccessible() {
        return this.#billingAccessible;
    }

    /** @returns {boolean} */
    get crawlersAccessible() {
        return this.#crawlersAccessible;
    }

    /** @returns {string} */
    get symbolsForIcon(){
        const words = this.#displayName.trim().split(/\s+/);
        const firstLetters = words.slice(0, 2).map(word => word.charAt(0));
        return firstLetters.join('');
    }

    /**
     * @param {UserJson} user
     * @returns {Org}
     */
    static createPersonalOrg(user){
        return new Org(user.id, user.name, user.displayName, AccessLevel.ADMIN, Org.TYPE.PERSONAL)
    }

    static empty(){
        return new Org('', '', '', AccessLevel.NONE, false, false)
    }

    /**
     * @param json
     * @returns {Org}
     */
    static parseServerJson(json) {
        if (!json)
            return Org.empty()
        const userAccessLevel = json.userAccessLevel == null
            ? AccessLevel.NONE : new AccessLevel(json.userAccessLevel);
        const type = json.type === Org.TYPE.ORG ? Org.TYPE.ORG : Org.TYPE.PERSONAL;
        return new Org(json.id, json.name, json.displayName, userAccessLevel,  type, json.billingAccessible === true,
            json.crawlersAccessible === true)
    }

    /**
     * @param {json[]} json
     * @returns {Org[]}
     */
    static parseServerJsonMany(json) {
        const result = []
        json.forEach(org => result.push(Org.parseServerJson(org)))
        return result;
    }

    static TYPE = {
        ORG: "ORG",
        PERSONAL: "PERSONAL",
    }
}
export default class StringToColorConverter {
	// Categorical colors authored by Tableau https://www.tableau.com/about/blog/2016/7/colors-upgrade-tableau-10-56782
	// original set includes 10 colors, but this one is a set of nine
	// (gray color is absent, since it used for null/empty values)
	static palette = Object.freeze(
		[	"#4e79a7", "#f28e2c", "#e15759",
			"#76b7b2", "#59a14f", "#edc949",
			"#af7aa1", "#ff9da7", "#9c755f"
		]);

	static palette_contrast = Object.freeze(
		[	"#1f78b4", "#6a3d9a", "#b15928",
			"#33a02b", "#e3211c", "#ff7f00"]
	);

	/**
	 * @param {string} str
	 * @param {string[]} palette
	 * @returns {string}
	 */
	static convert(str = '', palette = StringToColorConverter.palette) {
		let colorIndex = StringToColorConverter.hashString(str.toLowerCase()) % palette.length;
		return palette[colorIndex];
	}

	static hashString(str) {
		let hash = 0;
		if (str.length === 0) return hash;
		for (let i = 0; i < str.length; i++)
			hash += str.charCodeAt(i) % 21;
		return hash;
	}
}


import DialogUtils from '../DialogUtils.js'

export default class NotificationDialogHtml {
    static SELECTOR = '#notification-dialog';
    /** @type {HTMLDialogElement} */
    #dialog;
    #onOkCallbacks = [];
    #onCloseCallbacks = [];

    show(title, text) {
        this.#dialog = this.#createDialogElement(title, text);
        DialogUtils.setDefaultDialogListeners(this.#dialog);
        this.#addListeners()
        this.#dialog.showModal()
    }

    onOk(callback) {
        this.#onOkCallbacks.push(callback);
    }
    
    onClose(callback) {
        this.#onCloseCallbacks.push(callback);
    }

    remove() {
        this.#dialog.remove();
        this.#dialog = null;
    }

    /**
     * @returns {boolean} - whether the dialog is rendered on the page
     */
    isShown() {
        return this.#dialog != null;
    }

    #addListeners(){
        this.#dialog.querySelector('.btn-ok').addEventListener('click', ()=>{
            this.#onOkCallbacks.forEach(callback => callback());
            this.remove();
        });
        this.#dialog.addEventListener('close', ()=>{
            this.#onCloseCallbacks.forEach(callback => callback());
            this.remove();
        })
    }

    /**
     *
     * @param {string} title
     * @param {string} text
     * @returns {Element}
     */
    #createDialogElement(title, text) {
        const dialog = document.createElement('dialog');
        dialog.setAttribute('id', 'notification-dialog');
        dialog.innerHTML = this.#template(title, text)
        document.body.insertAdjacentElement('beforeend', dialog);
        return dialog;
    }
    #template(title, text) {
        return `
          <header class="popup__header">${title}</header>
          <main class="popup__main-content popup__main-content--text-only">
             ${text}
          </main>
          <footer class="popup__footer popup__footer--main-buttons-only">
            <button class="button button--contained btn-ok btn-cancel">Ok</button>
          </footer>
       `
    }
}
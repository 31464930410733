import AddInjectionDialogHtml from "./AddInjectionDialogHtml.js";
import UploadInjectionController from "../../js/controller/UploadInjectionController.js";

export default class AddInjectionDialogCmp {
    /** @type {AddInjectionDialogHtml}  */
    #html = new AddInjectionDialogHtml();
    /** @type {UploadInjectionController} */
    #uploadInjectionController;

    /**
     * @param {InjectionRepository} injectionRepository
     * @param {GlobalSettingsRepository} globalSettingsRepository
     * @param {BatchRepository} batchRepository
     * @param {Org} org
     * @param {SecurityContext} securityContext
     */
    constructor(injectionRepository, globalSettingsRepository, batchRepository,
                org, securityContext) {
        this.#uploadInjectionController = new UploadInjectionController(injectionRepository,
            globalSettingsRepository, batchRepository, org, securityContext);
        this.#html.onUpload(this.#uploadInjection.bind(this))
    }

    show() {
        this.#html.show();
        this.#uploadInjectionController.init(this.#html.getDropArea());
        this.#uploadInjectionController.onDrop(this.remove.bind(this))
    }

    remove() {
        this.#html.remove();
    }

    /** @param {function} callback */
    onCancel(callback) {
        this.#html.onCancel(callback);
    }

    /**
     * @param {File} file
     * @returns {Promise<void>}
     */
    async #uploadInjection(file) {
        await this.#uploadInjectionController.onFileDrop(file);
        this.remove()
    }
}

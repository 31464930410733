import {safeText} from "../../js/util/CommonUtils.js"
import HtmlElements from "../../js/util/HtmlElements.js";

export default class FileDropAreaHtml {

	constructor() {
		this._root = null;
		this._onEnterCallbacks = [];
		this._onLeaveCallbacks = [];
		this._onDropCallbacks = [];
		this._isMouseWithinArea = false;
	}

	/**
	 * @param {Element} element
	 * @param {{svgIcon?: string, message?:string, showOverlay?:boolean}} props
	 */
	init(element, {svgIcon, message, showOverlay = true}) {
		this._root = element;
		element.addEventListener('dragover', (evt) => {
			evt.preventDefault();
			evt.stopPropagation()
		}, true);
		element.addEventListener('dragenter', (evt) => {
			evt.preventDefault();
			if (!this._isMouseWithinArea) {
				this._isMouseWithinArea = true;
				if (showOverlay)
					this._showOverlay(
						message || FileDropAreaHtml.#DEFAULT_MESSAGE,
						svgIcon || FileDropAreaHtml.#DEFAULT_ICON
					);
				for (const onEnterCallback of this._onEnterCallbacks)
					onEnterCallback();
			}
		}, true);
		element.addEventListener('dragleave', (evt) => {
			if (!element.contains(evt.relatedTarget)){
				this._isMouseWithinArea = false;
				if (showOverlay)
					this._removeOverlay();
				for (const onLeaveCallback of this._onLeaveCallbacks)
					onLeaveCallback();
			}
		}, true);
		element.addEventListener('drop', async (evt) => {
			evt.preventDefault();
			evt.stopPropagation();
			this._isMouseWithinArea = false;
			if (showOverlay)
				this._removeOverlay();
			if (this._isDragFiles(evt))
				for (const cb of this._onDropCallbacks)
					cb(evt.dataTransfer.files);
		}, true);
	}

	onDragEnter(cb) {
		this._onEnterCallbacks.push(cb);
	}

	onDragLeave(cb) {
		this._onLeaveCallbacks.push(cb);
	}

	onDrop(cb) {
		this._onDropCallbacks.push(cb);
	}

	/**
	 * Browsers allow dragging elements like {@code <a>} and {@code <img>}, we don't want to think user uploads
	 * a file in this cases.
	 *
	 * @param {DragEvent} evt
	 * @private
	 */
	_isDragFiles(evt) {
		// Seems like the file list is present only when dropping and is always empty when we're dragging.
		// So doesn't make sense to add this check to any handler by for the 'drop' event.
		return evt.dataTransfer.files.length > 0;
	}

	/**
	 * @param {string} message
	 * @param {string} icon
	 * @private
	 */
	_showOverlay(message, icon) {
		this._root.insertAdjacentHTML("beforeend", this._htmlTemplate(message, icon));
	}

	_removeOverlay() {
		const overlay = this._getOverlayElement();
		overlay && overlay.remove();
	}

	/**
	 * @param {string} message
	 * @param {string} icon
	 * @returns {string}
	 * @private
	 */
	_htmlTemplate(message, icon) {
		return `<div class="${FileDropAreaHtml.#OVERLAY_CSS_CLASS}">
                	${icon}
                	<span>${safeText(message)}</span>
              </div>`
	}

	/**
	 * For testing
	 * @private
	 */
	_getOverlayElement(){
		return this._root.querySelector('.' + FileDropAreaHtml.#OVERLAY_CSS_CLASS);
	}


	static #DEFAULT_ICON = HtmlElements.iconByName('upload');
	static #DEFAULT_MESSAGE = "Drop or paste structure here";
	static #OVERLAY_CSS_CLASS = "drag-n-drop-area";
}

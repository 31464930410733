export default class HomePageHeaderHtml {
    #root;

    init(headerEl) {
        this.#root = headerEl;
        window.addEventListener('scroll', () => {
            headerEl.classList.toggle('scroll', document.scrollingElement.scrollTop !== 0);
        });
    }

    /** @returns {HTMLElement} */
    getUserHeaderElement() {
        return this.#root.querySelector('.page-header__section--user');
    }
}
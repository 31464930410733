import IonGenerationProps from "./IonGenerationProps.js";
import IonConfig from "./IonConfig.js";
import {round} from "../../js/util/CommonUtils.js";

export default class EicExtractionProps {
    /** @type {IonGenerationProps} */
    #ionGenProps;
    /** @type {IonConfig[]} */
    #ionConfigs;

    constructor(ionGenProps, ionConfigs) {
        this.#ionGenProps = ionGenProps;
        this.#ionConfigs = ionConfigs;
    }

    get ionMode() {
        return this.#ionGenProps.ionMode;
    }

    /** @returns {string} */
    get ionModeDisplayName() {
        const mode = this.#ionGenProps.ionMode;
        const sign = mode.endsWith("P") ? "+" : "-";
        return mode.slice(0, -1) + sign;
    }

    get qualifiersMz() {
        return this.#ionConfigs.filter(ic => ic.ql).map(ic => ic.mz);
    }

    get quantifiersMz() {
        return this.#ionConfigs.filter(ic => ic.qn).map(ic => ic.mz);
    }

    get chargedIonNames() {
        return this.#ionGenProps.fragments
            .filter(f => f.enabled && f.isCharged())
            .map(ic => ic.nameWithEffectOnAnalyte);
    }

    get neutralIonNames() {
        return this.#ionGenProps.fragments
            .filter(f => f.enabled && !f.isCharged())
            .map(ic => ic.nameWithEffectOnAnalyte);
    }

    /** @returns {IonGenerationProps} */
    get ionGenerationProps() {
        return this.#ionGenProps;
    }

    /** @return {IonConfig[]} */
    get ionConfigs() {
        return this.#ionConfigs;
    }

    set ionConfigs(configs) {
        this.#ionConfigs = configs;
    }

    get massResolution() {
        return this.#ionGenProps.massResolution;
    }

    set massResolution(value) {
        this.#ionGenProps.massResolution = value;
    }

    get intensityResolutionPercent() {
        return round(this.#ionGenProps.qualifierIntensityResolution * 100);
    }

    setIntensityResolutionPercent(value) {
        this.#ionGenProps.qualifierIntensityResolution = round(value / 100, 2);
    }

    /** @returns {EicExtractionProps} */
    copy() {
        return new EicExtractionProps(
            this.#ionGenProps.copy(),
            this.#ionConfigs.map(ic => ic.copy())
        );
    }

    equals(other) {
        if (this === other) return true;
        if (other === null || !(other instanceof EicExtractionProps)) return false;
        return this.#ionGenProps.equals(other.#ionGenProps) &&
            this.#ionConfigs.length === other.#ionConfigs.length &&
            this.#ionConfigs.every((ic, i) => ic.equals(other.#ionConfigs[i]));
    }

    toServerJson() {
        return {
            ionGenerationProps: this.#ionGenProps.toServerJson(),
            ionConfigs: this.#ionConfigs.map(ic => ic.toServerJson()),
        }
    }

    static parseServerJson(j) {
        return new EicExtractionProps(IonGenerationProps.parseServerJson(j.ionGenerationProps),
            IonConfig.parseMany(j.ionConfigs));
    }
}
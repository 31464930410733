import FragmentConfig from "./FragmentConfig.js";

export default class IonGenerationProps {
    /** @type {string} */
    #ionMode;
    /** @type {FragmentConfig[]} */
    #fragments;
    /** @type {number} */
    #qualifierIntensityResolution;
    #massResolution

    constructor(ionMode, fragments = [], qualifierIntensityResolution, massResolution) {
        this.#ionMode = ionMode;
        this.#fragments = fragments;
        this.#qualifierIntensityResolution = qualifierIntensityResolution;
        this.#massResolution = massResolution;
    }

    /** @returns {string} */
    get ionMode() {
        return this.#ionMode;
    }

    /** @returns {FragmentConfig[]} */
    get fragments() {
        return this.#fragments;
    }

    putFragment(oldFragment, newFragment) {
        const index = this.#fragments.findIndex(f => f.equals(oldFragment));
        if (index === -1)
            this.#fragments.push(newFragment);
        else
            this.#fragments[index] = newFragment;
    }

    removeFragment(fragment) {
        this.#fragments = this.#fragments.filter(f => !f.equals(fragment) );
    }

    get qualifierIntensityResolution() {
        return this.#qualifierIntensityResolution;
    }

    set qualifierIntensityResolution(value) {
        this.#qualifierIntensityResolution = value;
    }

    get massResolution() {
        return this.#massResolution;
    }

    set massResolution(value) {
        this.#massResolution = value;
    }

    getFragmentByNameWithEffectOnAnalyte(nameWithEffectOnAnalyte) {
        return this.#fragments.find(f => f.nameWithEffectOnAnalyte === nameWithEffectOnAnalyte);
    }

    getFragmentNames() {
        return this.#fragments.map(f => f.nameWithEffectOnAnalyte);
    }

    copy() {
        return new IonGenerationProps(
            this.#ionMode,
            this.#fragments.map(f => f.copy()),
            this.#qualifierIntensityResolution,
            this.#massResolution
        );
    }

    equals(other) {
        if (this === other) return true;
        if (!(other instanceof IonGenerationProps)) return false;
        return this.#ionMode === other.#ionMode
            && this.#qualifierIntensityResolution === other.#qualifierIntensityResolution
            && this.#massResolution === other.#massResolution
            && this.#fragments.length === other.#fragments.length
            && this.#fragments.every((f, i) => f.equals(other.#fragments[i]));
    }

    toServerJson() {
        return {
            ionMode: this.#ionMode,
            fragments: this.#fragments.map(f => f.toServerJson()),
            qualifierIntensityResolution: this.#qualifierIntensityResolution,
            massResolution: this.#massResolution
        }
    }

    static parseServerJson(j) {
        return new IonGenerationProps(j.ionMode, FragmentConfig.parseMany(j.fragments), j.qualifierIntensityResolution,
            j.massResolution);
    }
}
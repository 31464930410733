/** @param {string[]} htmlString */
export function createNewsString(htmlString){
    const arr = [];
    for (const str of htmlString) {
        arr.push(str)
    }
    return JSON.stringify(arr)
}

/**
 * Function to transform HTML into Swagger UI request body
 * @param {string[]} htmlString
 */
export function htmlToSwaggerRequest(htmlString) {
    return  {
        key: "home_news",
        val: createNewsString(htmlString)
    };
}

export default class SignInSectionHtml {
    #rootEl;
    #callbacks = {
        signIn: () => {},
        signUp: () => {}
    }

    init(rootEl) {
        this.#rootEl = rootEl;
    }
    clear() {
        this.#rootEl.replaceChildren();
    }

    show() {
        this.#rootEl.innerHTML = `
            <button class="button button--text visually-hidden" id="sign-up">Sign-up</button>
            <button class="button button--outlined" id="sign-in">Sign in</button>
        `;
        this.#setSignInButtonsListeners();
    }

    onSingInButtonClick(callback) {
        this.#callbacks.signIn = callback;
    }

    onSignUpButtonClick(callback) {
        this.#callbacks.signUp = callback;
    }

    #setSignInButtonsListeners() {
        this.#rootEl.querySelector('#sign-in').addEventListener('click', () => {
            this.#callbacks.signIn();
        });
        this.#rootEl.querySelector('#sign-up').addEventListener('click', () => {
            this.#callbacks.signUp();
        });
    }
}
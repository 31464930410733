import Org from "../entities/organization/Org.js";
import UserWithTeams from "../entities/user/UserWithTeams.js";

export default class OrgRepository {
    #httpClient;
    #endpointUrl;

    constructor(httpClient, endpointUrl = '/api/org') {
        this.#httpClient = httpClient;
        this.#endpointUrl = endpointUrl;
    }

    /**
     * @param {string} name
     * @return {Promise<Org>}
     */
    async getOrganization(name){
        const response = await this.#httpClient
            .fetch(`${this.#endpointUrl}?name=${name}`, {
                method: 'GET',
                headers: {
                    "Accept": "application/json"
                },
                responseType: 'json'
            });
        return Org.parseServerJson(response)
    }

    /**
     * @param {string} name
     * @returns {Promise<Org>}
     */
    async createOrganization(name){
        const response = await this.#httpClient
            .fetch(`${this.#endpointUrl}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                responseType: 'json',
                body: JSON.stringify({displayName: name}),
            });
        return Org.parseServerJson(response);
    }

    /**
     * @param {string} orgId
     * @param {string} name
     * @returns {Promise<UserWithTeams[]>}
     */
    async getOrgUsers(orgId, name){
        const nameParam = name || "";
        const resp = await this.#httpClient
            .fetch(`${this.#endpointUrl}/${orgId}/members?name=${encodeURIComponent(nameParam)}`,
                {
                    method: 'GET',
                    headers: {
                        "Accept": "application/json"
                    },
                    responseType: 'json',
                });
        return UserWithTeams.parseServerJsonMany(resp)
    }
}
export default class AliasColor {
	#alias;
	#color;

	constructor(alias, color) {
		this.#alias = alias;
		this.#color = color;
	}

	get alias() {
		return this.#alias;
	}

	get color() {
		return this.#color;
	}

	toServerJson() {
		return {
			alias: this.#alias,
			color: this.#color
		}
	}
}
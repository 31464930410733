import StringToColorConverter from "./StringToColorConverter.js";
import {safeText} from "./CommonUtils.js";

/**
 * @param {Org} org
 * @param {string?} className
 */
export function getOrgIcon(org, className = "") {
    const color = StringToColorConverter.convert(org.id);
    return `<div class="${className} account-icon" style="background: ${color};">
            <span class="org-icon__symbols">${safeText(org.symbolsForIcon.toUpperCase())}</span>
          </div>`
}
export default class XMLHttpRequestWrapper {
	#xhr;

	constructor(xhr = new XMLHttpRequest()) {
		this.#xhr = xhr;
	}

	/**
	 * @param {string} url
	 * @param {{method?:string, headers:any, responseType?: string, body?:string,
	 * onUploadProgress:function, returnRequestObject:boolean, userAuthenticated:boolean}} options
	 * @returns {Promise<any>}
	 */
	fetch(url, options) {
		return new Promise((resolve, reject) => {
			this.#xhr.open(options.method || "GET", url);
			this.#setRequestHeaders(options.headers);
			this.#setResponseType(options.responseType);
			this.#xhr.upload.onprogress = (event) => {
				if (event.lengthComputable && typeof options.onUploadProgress === 'function')
					options.onUploadProgress(event.loaded, event.total);
			};
			this.#xhr.onload = () => {
				if (XMLHttpRequestWrapper.#isSessionExpired(options.userAuthenticated, this.#xhr)) {
					reject({request: this.#xhr, sessionExpired: true});
					return;
				}
				const status = this.#xhr.status;
				const response = this.#xhr.response;
				if (status >= 200 && status < 300) {
					if (options.returnRequestObject)
						resolve(this.#xhr);
					else
						resolve(response);
				} else {
					reject({request: this.#xhr, sessionExpired: false});
				}
			};
			this.#xhr.send(options.body);
		});
	}

	abort() {
		this.#xhr.abort();
	}

	#setRequestHeaders(headers) {
		for (const [key, value] of Object.entries(headers || {}))
			this.#xhr.setRequestHeader(key, value);
	}

	#setResponseType(responseType) {
		this.#xhr.responseType = responseType || 'text';
	}

	/**
	 * @param {boolean} isUserAuthenticated
	 * @param {*} request
	 * @returns {boolean}
	 */
	static #isSessionExpired(isUserAuthenticated, request) {
		const cacheControl = request.getResponseHeader('cache-control');
		if(!cacheControl || !cacheControl.includes('no-cache'))
			// Can't check if user is logged in if the response is cached. Browser caches headers too, so the
			// information about whether the user is logged in may not be actual anymore. So we can't really
			// say if user is logged in NOW. Falling back to say that the session didn't expire. If it actually _is_
			// expired, then some other, non-cacheable endpoint will be invoked anyway, and it will know.
			//
			// We can't return `true` in this scenario because consider this:
			// 1. User was logged out, and opened a publicly available resource, some responses were cached
			// 2. User logs in, opens the same resource - and the previously cached responses say that the user is
			//    not logged in - and we show a dialog asking to log in (while the user is already logged in)
			return false;
		return isUserAuthenticated && request.getResponseHeader('elsci-user-logged-in') === 'false'
	}
}
export default class UserCapabilities {
    /** @type {boolean} */
    #canCreateOrgs;
    /** @type {boolean} */
    #appAdmin;
    #virgin;

    /**
     * @param {boolean} canCreateOrgs
     * @param {boolean} appAdmin
     * @param {boolean} virgin
     */
    constructor(canCreateOrgs, appAdmin, virgin) {
        this.#canCreateOrgs = canCreateOrgs;
        this.#appAdmin = appAdmin;
        this.#virgin = virgin;
    }

    /** @returns {boolean} */
    get canCreateOrgs(){
        return this.#canCreateOrgs;
    }

    /** @returns {boolean} */
    get appAdmin(){
        return this.#appAdmin;
    }

    /** @returns {boolean} */
    get virgin(){
        return this.#virgin;
    }

    static parseJson(json = {}){
        return new UserCapabilities(json.canCreateOrgs === true, json.appAdmin === true, json.virgin === true)
    }
}

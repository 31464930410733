import FragmentType from "./FragmentType.js";
import {round, safeText} from "../../js/util/CommonUtils.js";

export default class FragmentConfig {
    #name;
    #mf;
    #structure;
    #charge;
    #maxCount
    #emw;
    /** @type {FragmentType} */
    #type;
    /** @type {boolean} */
    #enabled;

    constructor(name, mf, struct, charge, maxCount, emw, enabled) {
        this.#name = name;
        this.#mf = mf;
        this.#structure = struct;
        this.#charge = charge;
        this.#maxCount = maxCount;
        this.#emw = emw;
        this.#type = FragmentType.fromIonConfig(charge, maxCount);
        this.#enabled = enabled;
    }

    /**
     * @returns {number} - charge, can be negative, zero or positive
     */
    get charge() {
        return this.#charge;
    }

    /**
     * @returns {number} - maxCount, can be negative - fragment is subtracted from analyte,
     * or positive - fragment is added to analyte
     */
    get maxCount() {
        return this.#maxCount;
    }

    set maxCount(value) {
        this.#maxCount = value;
    }

    /**
     * @returns {number} - absolute value of maxCount, to display in UI
     */
    get maxCountAbs() {
        return Math.abs(this.#maxCount);
    }

    /**
     * @returns {string} - name of fragment, can be empty string
     */
    get name() {
        return this.#name;
    }

    /**
     * @returns {string} - molecular formula of fragment, can be empty if fragment is electron
     */
    get mf() {
        return this.#mf || "";
    }

    /**
     * @returns {string} - structure id
     */
    get structure() {
        return this.#structure;
    }

    /**
     * @returns {number} - electron mass weight, can be zero if fragment is not electron
     */
    get emw() {
        return round(this.#emw, 5);
    }

    get enabled(){
        return this.#enabled;
    }

    set enabled(isEnabled){
        this.#enabled = isEnabled;
    }

    /**
     * @returns {string} - name of fragment with effect on analyte, e.g. "+H", "-H2O", "+e"
     */
    get nameWithEffectOnAnalyte() {
        const name = this.#name || this.#mf;
        return this.#type.getGainOrLoseSign() + name;
    }

    /**
     * @returns {string} - molecular formula of fragment with effect on analyte, e.g. "+1.0072", "-51.345", "0"
     */
    get massWithEffectOnAnalyteText() {
        if (this.emw === 0)
            return "" + this.emw;
        return this.#type.getGainOrLoseSign() + this.emw;
    }

    /**
     * @returns {string} - full name of ion, e.g. "[M-H]⁻", "[M-H2O]", "[M-e]⁺"
     */
    get ionNameHtml() {
        let sign = "";
        const polarity = this.#type.polarity;
        if (polarity !== 0){
            const ionCharge = polarity * this.type.getSignedMaxCountValue(1);
            sign = ionCharge > 0 ? FragmentConfig.#superScriptPlus : FragmentConfig.#superScriptMinus;
        }
        return `[M${safeText(this.nameWithEffectOnAnalyte)}]${sign}`;
    }

    /**
     * @returns {boolean} - false if fragment is neutral, true if fragment is charged
     */
    isCharged() {
        return this.#charge !== 0;
    }

    /**
     * @returns {boolean} - true if fragment is electron
     */
    isElectron() {
        return this.#mf == null && this.#emw === 0;
    }

    /** @returns {FragmentType} */
    get type() {
        return this.#type;
    }

    /**
     * @returns {FragmentConfig} - deep copy of this object
     */
    copy() {
        return new FragmentConfig(this.#name, this.#mf, this.#structure, this.#charge, this.#maxCount, this.#emw, this.#enabled);
    }

    equals(other) {
        if (this === other) return true;
        if (!(other instanceof FragmentConfig)) return false;
        return this.#name === other.#name
            && this.#mf === other.#mf
            && this.#structure === other.#structure
            && this.#charge === other.#charge
            && this.#maxCount === other.#maxCount
            && this.#emw === other.#emw
            && this.#enabled === other.#enabled;
    }

    toServerJson() {
        return {
            name: this.#name,
            mf: this.#mf,
            structure: this.#structure,
            charge: this.#charge,
            maxCount: this.#maxCount,
            enabled: this.#enabled
        }
    }

    static parseMany(arr) {
        return arr.map(el => FragmentConfig.parseServerJson(el));
    }

    static parseServerJson(j) {
        return new FragmentConfig(j.name, j.mf, j.structure, j.charge, j.maxCount, j.emw, j.enabled);
    }

    static #superScriptMinus = "\u207B";
    static #superScriptPlus = "\u207A";
}
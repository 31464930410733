export default class DialogUtils {
    /**
     * @deprecated Use {@link DialogUtils.setDefaultDialogListeners} instead
     */
    static addEscAndEnterHandler(dialog) {
        if (!dialog.parentElement.classList.contains("page-overlay"))
            throw new Error("DialogUtils.addEscAndEnterHandler: dialog must be a child of page-overlay");
        const okBtn = dialog.querySelector('.btn-ok');
        const cancelBtn = dialog.querySelector('.btn-cancel');

        dialog.parentElement.addEventListener('keyup', (evt) => {
            evt.stopPropagation();
        });

        // We changed event "keyup" on "keydown" and in the future want to use only this type of keyboard event
        dialog.parentElement.addEventListener('keydown', (evt) => {
            evt.stopPropagation();
            if (evt.repeat)
                return
            switch (evt.key) {
                case 'Enter' : {
                    if (okBtn && !okBtn.getAttribute('disabled') === true)
                        okBtn.dispatchEvent(new Event('click'))
                    break;
                }
                case 'Escape' :
                    if (cancelBtn && !cancelBtn.getAttribute('disabled') === true)
                        cancelBtn.dispatchEvent(new Event('click'))
            }
        });
        // prevent dialog loosing its focus when clicking on overlay
        dialog.parentElement.addEventListener('click', (evt) => {
            if (evt.target === dialog.parentElement)
                dialog.focus();
        });
        // when button is clicked, it becomes disabled and focus is lost, so we need to focus dialog again
        dialog.querySelectorAll('button').forEach(btn =>
            btn.addEventListener('click', () => dialog.focus())
        );
        dialog.focus();
    }

    /**
     * It works only with HTMLDialogElement
     * @param {HTMLDialogElement} dialog
     */
    static setDefaultDialogListeners(dialog) {
        if (!dialog instanceof HTMLDialogElement)
            throw new Error("DialogUtils.addEnterHandler: dialog must be an instance of HTMLDialogElement");
        dialog.addEventListener('keyup', (evt) => evt.stopPropagation());
        // when button is clicked, it becomes disabled and focus is lost, so we need to focus dialog again
        dialog.querySelectorAll('button').forEach(btn =>
            btn.addEventListener('click', () => dialog.focus())
        );
        const okBtn = dialog.querySelector('.btn-ok');
        dialog.addEventListener('keydown', (evt) => {
            evt.stopPropagation();
            if (!evt.repeat && evt.key === 'Enter' && okBtn && !okBtn.hasAttribute('disabled')) {
                evt.preventDefault();
                okBtn.dispatchEvent(new Event('click'));
            }
        });
        const cancelBtn = dialog.querySelector('.btn-cancel');
        if (cancelBtn)
            cancelBtn.addEventListener('click', () => dialog.dispatchEvent(new Event('cancel')));
    }

    static initMutationObserverToPreventScrollWhenDialogIsOpened() {
        // saving a mutation observer inside the document body allows us to:
        // 1. check if we already init such observer
        // 2. have access to it (to stop observer)
        if (document.body.mutationObserverToPreventScrollWhenDialogIsOpened)
            return;
        // .scroll-hidden adds style "overflow: hidden" to the html element
        const onChildListChanged = (_mutationList, _observer) =>
            document.documentElement.classList.toggle('scroll-hidden', DialogUtils.#isAnyDialogVisible());
        const mutationObserver = new MutationObserver(onChildListChanged);
        // Listen only for childList changes, since dialogs always added directly to the document body
        mutationObserver.observe(document.body, {childList: true});
        document.body.mutationObserverToPreventScrollWhenDialogIsOpened = mutationObserver;
    }

    static #isAnyDialogVisible() {
        return !!document.querySelectorAll('dialog[open]').length;
    }
}

export default class UserJson {
    /**
     * @param {string} id
     * @param {string} name
     * @param {string} firstname
     * @param {string} lastname
     * @param {boolean} inTeam
     * @param {boolean} inOrg
     */
    constructor(id, name, firstname, lastname, inTeam, inOrg) {
        this.id = id;
        this.name = name;
        this.firstname = firstname;
        this.lastname = lastname;
        this.inTeam = inTeam;
        this.inOrg = inOrg;
    }

    get displayName() {
        if (!this.firstname || !this.lastname)
            return this.name;
        return this.firstname + ' ' + this.lastname;
    }

    /** @returns {string} */
    get fullName() {
        let fullName = '';
        if (this.firstname)
            fullName += this.firstname
        fullName += ' '
        if (this.lastname)
            fullName += this.lastname;
        return fullName.trim()
    }

    /** @returns {string} */
    get initials() {
        if (!this.firstname || !this.lastname)
            return this.name[0].toUpperCase();
        return (this.firstname[0] + this.lastname[0]).toUpperCase();
    }

    /** @returns {UserJson} */
    createCopy(){
        return new UserJson(this.id, this.name, this.firstname, this.lastname)
    }

    /** @returns {UserJson} */
    static parseServerJson(json) {
        if (!json)
            return new UserJson(null, 'Unknown', null, null, false, false);
        return new UserJson(json.id, json.name, json.firstname, json.lastname, json.inTeam === true, json.inOrg === true);
    }

    /** @returns {UserJson[]} */
    static parseServerJsonMany(usersJson) {
        return usersJson.map(json => (UserJson.parseServerJson(json)));
    }
}
export default class MethodInfo {
  constructor(ms, inlet) {
    this.ms = ms;
    this.inlet = inlet;
  }

  /**
   * @param json
   * @return {MethodInfo}
   */
  static parseServerJson(json) {
    return new MethodInfo(json.ms, json.inlet)
  }
}

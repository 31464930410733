export default class HttpError extends Error {
    /**
     * @type {{request:*, sessionExpired: boolean}}
     */
    #reason;
    /**
     * @type {SecurityContext}
     */
    #securityContext;

    /**
     * @param {{request:*, sessionExpired: boolean}} reason
     * @param {SecurityContext} securityContext
     */
    constructor(reason, securityContext) {
        super(`Http error ${reason.sessionExpired ? 'session expired' : reason.request.status}.`);
        this.#reason = reason;
        this.#securityContext = securityContext;
    }
    /**
     * @returns {number}
     */
    get status() {
        return this.#reason.request.status;
    }

    /** @returns {string} */
    get responseType(){
        return this.#reason.request.responseType
    }
    /**
     * @returns {*}
     */
    get response() {
        return this.#reason.request.response;
    }
    /**
     * @returns {SecurityContext}
     */
    get securityContext() {
        return this.#securityContext;
    }
    /**
     * @returns {boolean}
     */
    get isBadRequest() {
        return this.status === 400;
    }
    /**
     * @returns {boolean}
     */
    get isUnauthorized() {
        return this.status === 401;
    }
    /**
     * @returns {boolean}
     */
    get isForbidden() {
        return this.status === 403;
    }
    /**
     * @returns {boolean}
     */
    get isNotFound() {
        return this.status === 404;
    }
    /**
     * @returns {boolean}
     */
    get isInternalServerError() {
        return this.status === 500;
    }

    /**
     * @returns {boolean}
     */
    get isUserSessionExpired() {
        return this.#reason.sessionExpired;
    }
}

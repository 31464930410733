import XMLHttpRequestWrapper from './XMLHttpRequestWrapper.js';
import SecurityContext from "../entities/SecurityContext.js";
import UserWithOrgs from "../entities/user/UserWithOrgs.js";
import HttpError from "../entities/error/HttpError.js";

/**
 * A single place to create AJAX requests, which allows us to add global interceptors.
 */
export default class HttpClient {
	/** @type {SecurityContext} */
	#securityContext;
	#url;
	#customHeaders;

	constructor(url = "", securityContext, customHeaders = []) {
		if (!securityContext)
			throw new Error('Security context is required');
		this.#securityContext = securityContext;
		this.#url = url;
		this.#customHeaders = customHeaders;
	}
	/**
	 * @param url
	 * @param options
	 * @returns {Promise<*>}
	 */
	async fetch(url, options) {
		try {
			return await new XMLHttpRequestWrapper().fetch(this.#withRootUrl(url), this.#withCustomHeaders(options));
		} catch (/** @type {{request:*, sessionExpired: boolean}} */reason) {
			throw new HttpError(reason, this.#securityContext);
		}
	}

	/**
	 * Returns a promise that can be cancelled.
	 * @param url
	 * @param options
	 * @returns {{cancelRequest: (function(): void), promise: Promise<*>}}
	 */
	cancelableFetch(url, options) {
		const xhr = new XMLHttpRequestWrapper();
		const promise = xhr.fetch(this.#withRootUrl(url), this.#withCustomHeaders(options));
		return {cancelRequest: () => xhr.abort(), promise};
	}
	/**
	 * Requires API-url to fetch information about currently logged user (e.g. /api/me)
	 * @param {string} securityContextUrl
	 * @returns {Promise<SecurityContext>}
	 */
	static async createSecurityContext(securityContextUrl= '/api/me') {
		const req = await new XMLHttpRequestWrapper().fetch(securityContextUrl, {
			method: 'GET',
			headers: {
				"Accept": "application/json"
			},
			responseType: 'json',
			returnRequestObject: true
		});
		const signInUrl = req.getResponseHeader('Elsci-Login-Location');
		const signUpUrl = req.getResponseHeader('Elsci-Registration-Location');
		const jsonResponse = req.response;
		const user = jsonResponse.anonymous ? null : UserWithOrgs.parseServerJson(jsonResponse);
		return new SecurityContext(user, signUpUrl, signInUrl);
	}

	#withRootUrl(url) {
		return this.#url + url;
	}
	#withCustomHeaders(options) {
		options.headers = {...options.headers, ...this.#customHeaders.reduce((acc, curr) => {
				return { ...acc, ...curr };
			}, {})};
		options.userAuthenticated = this.#securityContext.isAuthenticated();
		return options;
	}
}

'use strict'

export default class InjectionRow {

    /**
     * @param {{
     *  injection: PivotedInjection,
     *  peaks:PivotedPeak[],
     *  substances:PivotedSubstance[],
     *  chromatograms:PivotedChromatogram[]
     * }} opts
     */
    constructor(opts) {
        /** @type {PivotedInjection} */
        this.injection = opts.injection;
        /** @type {PivotedPeak[]|*[]} */
        this.peaks = opts.peaks || [];
        /** @type {PivotedSubstance[]|*[]} */
        this.substances = opts.substances || [];
        /** @type {PivotedChromatogram[]|*[]} */
        this.chromatograms = opts.chromatograms || [];
    }

    getId(){
        return this.injection.id;
    }

    get id(){
        return this.getId()
    }

    get name(){
        return this.injection.name;
    }
    /**
     * @returns {AccessLevel}
     */
    get userAccessLevel(){
        return this.injection._userAccessLevel;
    }
    /** @returns {OnPlatePosition} */
    getOnPlatePosition(){
        return this.injection.pos;
    }

    getTimestamp(){
        return this.injection.timestamp;
    }

    containsPeakWithAlias(alias) {
        const a = alias.toLowerCase();
        return !!this.peaks.find(p=>p.substance.displayName.toLowerCase() === a);
    }
}
import VisualizationProperties from "../components/visualization/VisualizationProperties.js";
import AliasColors from "./AliasColors.js";
import PlateMeta from "../components/plate/PlateMeta.js";
import AccessLevel from "./AccessLevel.js";

export default class BatchMeta {

    /**
     * @param {string} id
     * @param {string} name
     * @param {PlateMeta[]} plates
     * @param {AliasColors} aliasColors
     * @param {VisualizationProperties[]} visualizations
     * @param {AccessLevel} userAccessLevel
     * @param {string} orgId
     */
    constructor(id, name, plates, aliasColors, visualizations,
                userAccessLevel, orgId) {
        this._id = id;
        this._name = name;
        this._plates = plates;
        this._aliasColors = aliasColors;
        this._visualizations = visualizations;
        this._userAccessLevel = userAccessLevel;
        this._orgId = orgId;
    }

    /** @returns {string} */
    get id() {
        return this._id;
    }

    /** @returns {string} */
    get name() {
        return this._name;
    }

    /** @returns {PlateMeta[]} */
    get plates() {
        return this._plates;
    }

    /** @returns {AliasColors} */
    get aliasColors() {
        return this._aliasColors;
    }

    get visualizations() {
        return this._visualizations;
    }

    /** @returns {string} */
    get orgId() {
        return this._orgId;
    }

    /** @returns {AccessLevel} */
    get userAccessLevel(){
        return this._userAccessLevel;
    }

    isWritable() {
        return this._userAccessLevel.meetsOrExceeds(AccessLevel.WRITE);
    }

    static parseServerJson(json) {
        const plateMetas = json.plates.map(jsonPlateMeta => PlateMeta.parseServerJson(jsonPlateMeta));
        let aliasColors = new AliasColors(json.aliases, json.colors);
        const vis = [];
        if (json.visualizations)
            for (const visJson of json.visualizations) {
                vis.push(VisualizationProperties.parseServerJson(visJson));
            }
        // Since BE doesn't send accessLevel yet, we need to set it to some default value
        let accessLevel = json.userAccessLevel == null ? AccessLevel.WRITE : new AccessLevel(json.userAccessLevel);
        return new BatchMeta(json.id, json.name, plateMetas, aliasColors, vis, accessLevel, json.org.id);
    }
}
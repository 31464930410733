import HomePageIoC from "./HomePageIoC.js";
import HttpClient from "../../js/repository/HttpClient.js";
import GlobalErrorHandler from "../../js/entities/error/GlobalErrorHandler.js";
import DialogUtils from "../../components/DialogUtils.js";
import HttpErrorHandler from "../../js/entities/error/HttpErrorHandler.js";
import {disableBFCache} from "../../js/util/CommonUtils.js";

window.onload = async () => {
    disableBFCache();
    DialogUtils.initMutationObserverToPreventScrollWhenDialogIsOpened();
    const globalErrorHandler = new GlobalErrorHandler();
    globalErrorHandler.init();
    globalErrorHandler.addHttpErrorHandler(new HttpErrorHandler());
    const securityContext = await HttpClient.createSecurityContext("api/me/home");
    const ioC = new HomePageIoC(securityContext);
    const homePage = ioC.getHomePageCmp();
    homePage.init(document.querySelector('.home-page'));
    await homePage.show();
}
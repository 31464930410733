import UserJson from './user/UserJson.js';
import Org from "./organization/Org.js";
import AccessLevel from "./AccessLevel.js";

export default class BatchListEntry{
  /**
   * @param id
   * @param name
   * @param created
   * @param {UserJson} creator
   * @param {Org} org
   * @param {AccessLevel} accessLevel
   */
  constructor(id, name, created, creator, org, accessLevel) {
    this._id = id;
    this._name = name;
    this._created = created;
    this._creator = creator;
    this._org = org
    this._accessLevel = accessLevel
  }

  /**
   * @deprecated use getter for id
   * @return {string}
   */
  getId() {return this._id}
  /**
   * @deprecated use getter for name
   * @return {string}
   */
  getDisplayName() {return this._name}
  /** @return {string} */
  getCreated() {return this._created}

  /** @return {string} */
  getCreatorDisplayName() {
      return this._creator.displayName;
  }

  /** @returns {AccessLevel} */
  get userAccessLevel(){
    return this._accessLevel;
  }

  /** @returns {string} */
  get name(){
    return this._name
  }

  /** @returns {string} */
  get id(){
    return this._id;
  }

  /** @returns {Org} */
  org() {
    return this._org
  }

  static parseServerJson(json){
    return new BatchListEntry(json.id, json.name, json.created, UserJson.parseServerJson(json.creator),
        Org.parseServerJson(json.org), new AccessLevel(json.accessLevel));
  }
}
import StringToColorConverter from "./StringToColorConverter.js";

/**
 * @param {UserJson} user
 * @param {string?} className
 */
export function getUserIcon(user, className = '') {
    const color = StringToColorConverter.convert(user.id);
    return `<div class="${className} account-icon" style="background: ${color};">
            <span class="user-icon__initials">${user.initials}</span>
          </div>`
}
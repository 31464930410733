import {safeHtml} from '../../js/util/CommonUtils.js';
import DialogUtils from "../DialogUtils.js";
import {createOrgNameUrlTemplate} from "../../js/util/UrlUtils.js";
export default class UploadInjectionDialogHtml {
    #dialog;
    /** @type {Org} */
    #org;
    #onStopLoadingCallback = () => {};
    #onCreateBatchCallback = () => {};

    showProgressBarView() {
        this.remove();
        this.#getDialog().innerHTML = UploadInjectionDialogHtml.#progressBarViewTemplate();
        this.#getStopButton().addEventListener('click', this.#onStopButtonClick.bind(this));
        this.#getDialog().showModal();
    }

    setProgressBarPercentsLoaded(percent) {
        this.#dialog.querySelector('.determinate').style.width = percent;
        this.#dialog.querySelector('#percents').textContent = percent;
    }

    showExtractionView() {
        this.remove();
        this.#getDialog().innerHTML = UploadInjectionDialogHtml.#extractionViewTemplate();
        this.#getCloseButton().addEventListener('click', this.#onCloseButtonClick.bind(this));
        this.#getDialog().showModal();
    }

    showImportResultView(importResult, org) {
        this.remove();
        this.#org = org;
        this.#getDialog().innerHTML = UploadInjectionDialogHtml.#importResultTemplate(importResult, this.#org);
        this.#getDialog().showModal();
        const showMoreButton = this.#getShowMoreButton();
        if (showMoreButton)
            showMoreButton.addEventListener('click', this.#onShowMoreButtonClick.bind(this));
        const showErrorButton = this.#getShowErrorButton();
        if (showErrorButton)
            showErrorButton.addEventListener('click', this.#onShowErrorButtonClick.bind(this, importResult.lastError));
        this.#getCloseButton().addEventListener('click', this.#onCloseButtonClick.bind(this));
        const openInjectionListButton = this.#getOpenInjectionListButton();
        if (openInjectionListButton)
            openInjectionListButton.addEventListener('click', this.#onopenInjectionListButtonClick.bind(this, this.#org));
        const createButton = this.#getCreateButton();
        if (createButton)
            createButton.addEventListener('click', this.#onCreateBatchButtonClick.bind(this));
    }

    onStopLoading(cb) {
        this.#onStopLoadingCallback = cb;
    }

    onCreateBatch(cb) {
        this.#onCreateBatchCallback = cb;
    }

    remove() {
        if (this.#dialog) {
            this.#dialog.remove();
            this.#dialog = null;
        }
    }

    #onStopButtonClick() {
        this.#onStopLoadingCallback();
        this.remove();
    }

    #onCloseButtonClick() {
        this.remove();
    }

    #onShowMoreButtonClick(evt) {
        evt.preventDefault();
        this.#getDialog().querySelector('ul.visually-hidden').classList.remove('visually-hidden');
        this.#getDialog().querySelector('#show-more').remove();
    }

    #onShowErrorButtonClick(error, evt) {
        evt.preventDefault();
        UploadInjectionDialogHtml.#showErrorMessageModal(error);
    }

    /**
     * @param {Org} org
     */
    #onopenInjectionListButtonClick(org) {
        const orgTemplate = createOrgNameUrlTemplate(org ? org.name : '');
        window.location.href = `${orgTemplate}/injections`
    }

    #onCreateBatchButtonClick() {
        this.remove();
        this.#onCreateBatchCallback();
    }

    #getShowMoreButton() {
        return this.#dialog.querySelector('#show-more');
    }

    #getShowErrorButton() {
        return this.#dialog.querySelector('#show-error');
    }

    #getStopButton() {
        return this.#dialog.querySelector('.button[data-action="stop"]');
    }

    #getCloseButton() {
        return this.#dialog.querySelector('.button[data-action="close"]');
    }

    #getOpenInjectionListButton() {
        return this.#dialog.querySelector('.button[data-action="openInjectionList"]');
    }

    #getCreateButton() {
        return this.#dialog.querySelector('.button[data-action="create"]');
    }

    #getDialog() {
        if (!this.#dialog) {
            const dialog = document.createElement('dialog');
            dialog.classList.add('upload-injection-dialog');
            document.body.appendChild(dialog);
            this.#dialog = dialog;
            this.#addListeners();
        }
        return this.#dialog;
    }

    #addListeners(){
        DialogUtils.setDefaultDialogListeners(this.#dialog)
        this.#dialog.addEventListener('close', this.remove.bind(this));
    }

    static #progressBarViewTemplate() {
        return `
                <div class="dialog-content">
                    <div class="dialog-row">
                        <span id="label">Uploading...</span>
                        <span id="percents"></span>
                        <div class="progress">
                            <div class="determinate"></div>
                        </div>
                    </div>
                </div>
                <div class="dialog-buttons">
                    ${this.#secondaryButton('Stop', 'stop', 'autofocus')}
                </div>`;
    }

    static #extractionViewTemplate() {
        return `
                <div class="dialog-content">
                    <div class="dialog-row">
                        <span id="label">Extracting...</span>
                    </div>
                </div>
                <div class="dialog-buttons">
                    ${this.#secondaryButton('Close', 'close', 'autofocus')}
                </div>
        `;
    }

    static #importResultTemplate(importResult, org) {
        const {successInjectionNames, failedDiscoveredInjections} = importResult;
        const total = successInjectionNames.length + failedDiscoveredInjections.length;
        if (total === 0)
            return UploadInjectionDialogHtml.#emptyImportResultTemplate();
        return this.#injectionListTemplate(org, importResult);
    }

    static #emptyImportResultTemplate() {
        return `
                <div class="dialog-content">
                    <p>No instrument files were found in zip.</p>
                    <span>Either there are no instrument files or the vendor is not supported. See </span>
                    <a href="https://elsci.io/docs/peaksel/dataimport/import-manual-upload.html">docs</a>
                    <span>.</span>
                </div>
                <div class="dialog-buttons">
                    ${this.#secondaryButton('Close', 'close', 'autofocus')}
                </div>`;
    }

    static #showErrorMessageModal(error) {
        let dialogElement = document.createElement('dialog');
        dialogElement.classList.add('upload-error-message-modal');
        dialogElement.innerHTML = `
            <div class="dialog-content">
                <p></p>
            </div>
            <div class="dialog-buttons">
                ${this.#secondaryButton('Close', 'close', 'autofocus')}
            </div>`;
        dialogElement.querySelector('p').textContent = error;
        document.body.appendChild(dialogElement);
        dialogElement.showModal();
        dialogElement.addEventListener('close', () => {
            dialogElement.remove();
        });
        dialogElement.querySelector('.button[data-action="close"]').addEventListener('click', () => {
            dialogElement.remove();
        });
    }

    static #injectionListTemplate(org, importResult) {
        const {successInjectionNames, failedDiscoveredInjections, lastError} = importResult;
        const total = successInjectionNames.length + failedDiscoveredInjections.length;
        const list = this.#createListEntries(org, importResult);
        const visibleListHtml = `<ul>${list.slice(0, UploadInjectionDialogHtml.#VISIBLE_ELEMENTS_COUNT).join('')}</ul>`;
        const hiddenListElements = list.slice(UploadInjectionDialogHtml.#VISIBLE_ELEMENTS_COUNT).join('');
        let optionalListHtml = '', optionalErrorButtonHtml = '';
        if (lastError) {
            optionalErrorButtonHtml = `
                <div class="dialog-row">
                    <span>Click </span><a id="show-error" href="">here</a><span> to see error message.</span>
                </div>`;
        }
        let closeButtonHtml, openInjectionListButtonHtml, createButtonHtml;
        switch (successInjectionNames.length) {
            case 0:{
                closeButtonHtml = this.#secondaryButton('Close', 'close', 'autofocus');
                openInjectionListButtonHtml = '';
                createButtonHtml = '';
                break;
            }
            case 1: {
                closeButtonHtml = this.#secondaryButton('Close', 'close');
                openInjectionListButtonHtml = this.#primaryButton('Show in list', 'openInjectionList', 'autofocus');
                createButtonHtml = this.#secondaryButton('Create batch', 'create');
                break;
            }
            default:{
                closeButtonHtml = this.#secondaryButton('Close', 'close');
                openInjectionListButtonHtml = this.#secondaryButton('Show in list', 'openInjectionList');
                createButtonHtml = this.#primaryButton('Create batch', 'create', 'autofocus');
            }
        }
        if (hiddenListElements.length > 0) {
            optionalListHtml = `<a id="show-more" href="">Show more</a>
                            <ul class="visually-hidden">
                                ${hiddenListElements}
                            </ul>`;
        }
        return `
                <div class="dialog-content">
                    <div class="dialog-row">
                        <span id="label">${successInjectionNames.length} injection(s) out of ${total} were uploaded successfully.</span>
                    </div>
                    <div class="dialog-row">
                        ${UploadInjectionDialogHtml.#SVG_DEFS + visibleListHtml + optionalListHtml}
                    </div>
                    ${optionalErrorButtonHtml}
                </div>
                <div class="dialog-buttons">
                        ${createButtonHtml} ${openInjectionListButtonHtml} ${closeButtonHtml}
                </div>`;
    }
    static #createListEntries(org, importResult){
        const {successInjectionNames, failedDiscoveredInjections, successInjectionIds} = importResult;
        const orgTemplate = org ? createOrgNameUrlTemplate(org.name) : "";
        const arr1 = [];
        for (let i = 0; i < successInjectionNames.length; i++) {
            arr1.push(safeHtml`<li>
                        <span>
                            <svg fill-opacity=".7" viewBox="0 0 24 24" width="18px">
                                <use href="#status-parsed"></use>
                            </svg>			
					    </span>
					    <a href="${orgTemplate}/injection/${successInjectionIds[i]}">${successInjectionNames[i]}</a>
				</li>`)
        }
        const arr2 = failedDiscoveredInjections.map((name) =>{
            return safeHtml`<li>
                        <span>
                            <svg fill-opacity=".7" viewBox="0 0 24 24" width="18px">
                                <use href="#status-parsing_failed"></use>
                            </svg>			
					    </span>
					    <span>${name}</span>
				</li>`
        });
        return arr1.concat(arr2);
    }

    static #primaryButton(name, action, attributes = '') {
        return this.#button('button--contained', name, action, attributes);
    }

    static #secondaryButton(name, action, attributes = '') {
        return this.#button('button--text', name, action, attributes);
    }

    static #button(classList, name, action, attributes = '') {
        return `<button class="button ${classList}" ${attributes} data-action="${action}">${name}</button>`
    }

    static #VISIBLE_ELEMENTS_COUNT = 3;
    static #SVG_DEFS = `
        <svg style="display: none">
            <defs>
                <g fill="rgb(0,128,0)" id="status-parsed">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                </g>
                <g fill="rgb(207,42,39)" id="status-parsing_failed">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                </g>
            </defs>
        </svg>`;
}


import {safeText} from "../../js/util/CommonUtils.js";

export default class OrgSideUserMenu {
    /** @type {HTMLDialogElement} */
    #menu;

    /**
     * @param {HTMLElement} parent
     * @param {Org[]} orgs
     */
    init(parent, orgs){
        this.#menu = document.createElement('dialog');
        parent.insertAdjacentElement('beforeend', this.#menu);
        this.#menu.classList.add('user-menu__side-menu');
        this.#menu.setAttribute('id', 'user-org-menu');
        orgs.map(o => this.#menu.insertAdjacentHTML("beforeend", this.#orgTemplate(o)));
        this.#addEventListeners()
    }

    show(){
        this.#menu.showModal()
    }

    close(){
        if(this.#menu)
            this.#menu.close()
    }

    #addEventListeners(){
        this.#menu.addEventListener('click', (evt)=>{
            evt.stopPropagation();
            this.#menu.close()
        })
    }
    /**
     * @param {Org} org
     * @returns {string}
     */
    #orgTemplate(org){
        return `<li class="user-menu__menu-item user-menu__menu-item--link">
                    <a href="/a/${org.name}" class="user-menu__link">${safeText(org.displayName)}</a>
                </li>`
    }
}
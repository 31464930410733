export default class Messages {
    /**
     * Messages which are common for all pages
     */
    static COMMON = {
        CANT_EDIT_IN_READ_ONLY_MODE: `You're in a read-only mode, only people with Edit permissions can introduce modifications`,
    }
    static INJECTION = {
        CHANGES_WILL_NOT_BE_SAVED_IN_READ_ONLY_MODE: 'You are in read-only mode, only people with Edit permissions can introduce modifications',
        LOW_ACCESS_LEVEL: "You don't have the required permissions to upload an injection"
    };
    static BATCH = {
        CHANGES_WILL_NOT_BE_SAVED_IN_READ_ONLY_MODE: `You can't modify this batch, all the changes will be lost after the page is refreshed.`,
    };
}
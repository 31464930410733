import Org from "../organization/Org.js";
import UserJson from "./UserJson.js";
import UserCapabilities from "./UserCapabilities.js";

export default class UserWithOrgs {
    /** @type {UserJson} */
    #basicUser;
    /** @type {Org[]} */
    #orgs;
    /** @type {UserCapabilities} */
    #capabilities;

    /**
     * @param {UserJson} basicUser
     * @param {Org[]} organizations
     * @param {UserCapabilities} capabilities
     */
    constructor(basicUser, organizations, capabilities) {
        this.#basicUser = basicUser;
        this.#orgs = organizations.sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.#capabilities = capabilities;
    }

    /** @returns {UserCapabilities} */
    get capabilities() {
        return this.#capabilities;
    }

    /** @returns {UserJson} */
    get basicUser() {
        return this.#basicUser;
    }

    /** @returns {Org[]} */
    get orgs(){
        return this.#orgs;
    }

    /** @returns {boolean} */
    get isVirgin(){
        return this.#capabilities.virgin;
    }

    /** @returns {boolean} */
    isAdmin(){
        return !!this.#capabilities.appAdmin;
    }

    /** @returns {UserWithOrgs} */
    static parseServerJson(json) {
        return new UserWithOrgs(UserJson.parseServerJson(json),
            Org.parseServerJsonMany(json.organizations), UserCapabilities.parseJson(json.capabilities));
    }

    /**
     * @param {{}[]} usersJson
     * @returns {UserWithOrgs[]}
     */
    static parseServerJsonMany(usersJson) {
        return usersJson.map(json => (UserWithOrgs.parseServerJson(json)));
    }
}
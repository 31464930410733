import SignInSectionHtml from "./SignInSectionHtml.js";

export default class SignInSectionCmp {
    #html = new SignInSectionHtml();

    /** @param {HTMLElement} rootEl */
    init(rootEl) {
        this.#html.init(rootEl);
    }

    /**
     * @param {SecurityContext} securityContext
     */
    setSecurityContext(securityContext) {
        if (!securityContext.isAuthenticated()) {
            this.#html.onSingInButtonClick(() => {
                window.location.href = securityContext.signInUrl;
            });
            this.#html.onSignUpButtonClick(() => {
                window.location.href = securityContext.signUpUrl;
            });
            this.#html.show();
        } else {
            this.#html.clear();
        }
    }
}
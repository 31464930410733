import PeakJson from "../entities/PeakJson.js";

export default class PeakList {

  /** @param {PeakJson[]} peaks */
  constructor(peaks) {
    this._peaks = peaks;
  }

  /**
   * @param id
   * @return {PeakJson}
   */
  getById(id) {
    return this._peaks.find(peak => peak.getId() === id)
  }

  /**
   * @param predicate
   * @return {PeakList}
   */
  filter(predicate) {
    return new PeakList(this._peaks.filter(predicate));
  }

  /**
   * @param {string} chromatogramId
   * @return {PeakList}
   */
  filterByChromatogramId(chromatogramId){
    return this.filter(peak=>peak.getChromatogramId() === chromatogramId)
  }

  /**
   * @param {string} substanceId
   * @return {PeakList}
   */
  filterBySubstanceId(substanceId){
    return this.filter(peak=>peak.getSubstanceId() === substanceId)
  }

  /** @param {PeakJson} peak */
  includes(peak) {
    return this._peaks.findIndex(p=>p.getId() === peak.getId()) !== -1
  }

  /** @param {PeakList} anotherList */
  intersection(anotherList) {
    return this.filter(peak => anotherList.includes(peak))
  }

  /** @param {PeakList} anotherList */
  filterOutList(anotherList) {
    return this.filter(peak => !anotherList.includes(peak))
  }

  /** @param {PeakJson} peakJson */
  filterOutPeak(peakJson) {
    return new PeakList(this._peaks.filter(peak=>peak.getId() !== peakJson.getId()))
  }

  /** @return {PeakList} */
  sortedByRetentionTime(){
    return new PeakList(this._peaks.slice().sort((a,b)=>a.getIndexRange().lower - b.getIndexRange().lower))
  }

  /**
   * @param {number} index
   * @return {PeakList}
   */
  getPeaksOverlappingIndex(index){
    return this.filter(/**@type PeakJson*/peak=>peak.getIndexRange().contains(index))
  }

  /** @return {boolean} */
  isEmpty(){return !this._peaks.length}

  /**
   * @param json
   * @returns {PeakList}
   */
  static fromServerJson(json) {
    return new PeakList(json.map(peak => PeakJson.parseServerJson(peak)));
  }
  /** @return {IterableIterator<PeakJson>} */
  [Symbol.iterator]() {
    return this._peaks.values();
  }
}

import Range from './Range.js';

export default class MassRange {
  /**
   * @param {Range[]} rangeList
   */
  constructor(rangeList) {
    this.rangeList = rangeList || []
  }

  /**
   * @return {boolean}
   */
  contains(value) {
    for (let range of this.rangeList) {
      if (range.contains(value)) return true;
    }
    return false;
  }

  /** @return {Range} */
  bounds(){
    let lo = Number.MAX_VALUE, hi = Number.MIN_VALUE;
    for (const range of this.rangeList) {
      lo = Math.min(lo, range.lower);
      hi = Math.max(hi, range.upper);
    }
    return new Range(lo, hi);
  }

  mergeOverlapping() {
    const ranges = [];
    const list = this.rangeList.slice();
    while (list.length > 0) {
      const current = list.shift();
      const idx = ranges.findIndex(r => r.overlaps(current));
      if (idx === -1)
        ranges.push(current);
      else
        ranges[idx] = ranges[idx].merge(current)
    }
    return ranges;
  }

  /**
   * @return {MassRange}
   */
  static parseServerJson(jsonRanges) {
    const rangeList = jsonRanges.map(jsonRange => Range.parseServerJson(jsonRange));
    return new MassRange(rangeList)
  }
}

import OnPlatePosition from '../components/plate/OnPlatePosition.js';
import UserJson from './user/UserJson.js';
import {formatDateForDisplayingInTable, formattedDateFromMillis} from "../util/CommonUtils.js";
import AccessLevel from "./AccessLevel.js";

export default class InjectionMetaJson {
	/**
	 * @param {string} id
	 * @param {string} name
	 * @param {number} acquired - milliseconds, null if not present
	 * @param {string} plateId
	 * @param {OnPlatePosition} pos
	 * @param {string} instrumentName
	 * @param {string} methodName
	 * @param {UserJson} creator
	 * @param {AccessLevel} userAccessLevel
	 * @param {string} orgId
	 */
	constructor(id, name, acquired, plateId, pos, instrumentName,
				methodName, creator, userAccessLevel, orgId) {
		this._id = id;
		this._name = name;
		this._acquired = acquired;
		this._timestamp = Date.parse(acquired);
		this._plateId = plateId;
		this._pos = pos;
		this._instrumentName = instrumentName;
		this._creator = creator;
		this._methodName = methodName;
		this._userAccessLevel = userAccessLevel;
		this._orgId = orgId
	}

	/** @returns {string} */
	get id(){
		return this.getId();
	}

	get name(){
		return this.getName();
	}

	/** @return {string} */
	getId() {
		return this._id;
	}

	/** @return {string} */
	getName() {
		return this._name;
	}

	/** @return {number} */
	getAcquiredTime() {
		return this._acquired
	}
	get acquiredTimeFormatted() {
		if (!this._acquired)
			return '';
		return formattedDateFromMillis(this._acquired)
	}

	/**
	 * We want to display the time, if there is a present date, and the date otherwise
	 * @returns {string} */
	get acquiredTimeOrDateForTable() {
		if (!this._acquired)
			return '';
		return formatDateForDisplayingInTable(this._acquired)
	}

	/** @return {number} */
	getTimestamp(){
		return this._timestamp;
	}

	/** @return {string} */
	getDisplayName() {
		return this._name ? this._name : this._id
	}

	/** @returns {string} */
	get plateId() {
		return this._plateId;
	}
	/**
	 * @returns {AccessLevel}
	 */
	get userAccessLevel() {
		return this._userAccessLevel;
	}
	/** @returns {OnPlatePosition} */
	getOnPlatePosition() {
		return this._pos;
	}

	getInstrumentName() {
		return this._instrumentName;
	}
	getMethodName() {
		return this._methodName;
	}

	/** @returns {string} */
	orgId() {
		return this._orgId
	}

	/**
	 * @param json
	 * @returns {InjectionMetaJson}
	 */
	static parseServerJson(json) {
		const pos = new OnPlatePosition(json.row, json.col);
		const creator = UserJson.parseServerJson(json.creator);
		// Because acquired time shouldn't usually be absent, we use primitives inside DTO on BE.
		// So it could be 0 if we don't introduce parse-time errors in the future.
		const acquired = json.acquired || null;
		const userAccessLevel = json.userAccessLevel == null ? AccessLevel.WRITE : new AccessLevel(json.userAccessLevel)
		return new InjectionMetaJson(
			json.id, json.name, acquired, json.plateId, pos,
			json.instrumentName, json.methodName, creator, userAccessLevel, json.orgId);
	}

	getCreatorDisplayName() {
		return this._creator.displayName;
	}
}
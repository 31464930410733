import DialogUtils from "../DialogUtils.js";

export default class BaseDialogHtml {
    #dialog;
    #callbacks = {
        onOk: ()=>{},
        onCancel: ()=>{}
    }

    show({title, bodyHtml, cssClass, btnOk, btnCancel}) {
        const dialog = this.#getDialogEl();
        dialog.classList.add(cssClass);
        dialog.innerHTML = this.#template(title, bodyHtml, btnOk, btnCancel);
        this.#setEventListeners();
        this.#dialog.showModal();
    }

    onOk(callback) {
        this.#callbacks.onOk = callback;
    }

    onCancel(callback) {
        this.#callbacks.onCancel = callback;
    }

    close(){
        this.#remove();
    }

    /** @return {boolean} */
    isAlreadyShown() {
        return !!this.#dialog;
    }
    /**
     * @param {string} selector
     * @returns {HTMLElement|null}
     */
    getElement(selector) {
        return this.#dialog.querySelector(selector);
    }
    #remove() {
        this.#dialog.remove();
        this.#dialog = null;
    }

    #setEventListeners() {
        DialogUtils.setDefaultDialogListeners(this.#dialog);
        const okBtn = this.#getOkButtonElement();
        if (okBtn)
            okBtn.addEventListener("click", this.#onOk.bind(this));
        this.#dialog.addEventListener("cancel", this.#onCancel.bind(this));
    }

    async #onOk() {
        this.#setAsyncTaskInProgress(true);
        await this.#callbacks.onOk();
        this.#remove();
    }

    #onCancel() {
        this.#callbacks.onCancel();
        this.#remove();
    }

    #setAsyncTaskInProgress(inProgress) {
        this.#dialog.querySelector('.progress').style.display = inProgress ? "block" : "none";
        this.#dialog.querySelectorAll('button').forEach(btn => btn.setAttribute('disabled', inProgress));
    }

    #getOkButtonElement() {
        return this.#dialog.querySelector('.btn-ok');
    }

    #getDialogEl() {
        if (!this.#dialog) {
            const dialog = document.body.insertAdjacentElement("beforeend", document.createElement('dialog'));
            dialog.classList.add('base-dialog');
            this.#dialog = dialog
        }
        return this.#dialog;
    }

    #template(title, bodyHtml, btnOk, btnCancel) {
        const okBtn = btnOk? `<button class="button button--contained btn-ok" autofocus>${btnOk}</button>` : "";
        const cancelBtn = btnCancel? `<button class="button button--text btn-cancel">${btnCancel}</button>` : "";
        return `
          <div class="progress"><div class="indeterminate"></div></div>
          <header class="dialog__header">${title}</header>
          ${bodyHtml}
          <footer class="dialog__footer dialog__footer--main-buttons-only">
              ${cancelBtn}
              ${okBtn}
          </footer>`;
    }
}
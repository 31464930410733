'use strict'

export default class PivotedChromatogram {
    constructor(name, area) {
        this.name = name;
        this.area = area;
    }

    isTotal(){
        return this.name.endsWith('Total')
    }
    isMs(){
        return this.name.startsWith('MS')
    }
}
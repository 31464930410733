import AliasColors from "../../../../../main/resources/static/js/entities/AliasColors.js";
import {createNewsString} from "../util/NewsUtils.js";

export default class GlobalSettingsRepository {
	#httpClient;
	#endpointUrl;

	constructor(httpClient, endpointUrl = '/api/settings') {
		this.#httpClient = httpClient;
		this.#endpointUrl = endpointUrl;
	}

	/**
	 *
	 * @param {string} key
	 * @return {Promise<string>}
	 */
	async getProp(key) {
		const response = await this.#httpClient
			.fetch(`${this.#endpointUrl}/props?keys=${encodeURIComponent(key)}`, {
				method: 'GET',
				headers: {
					"Accept": "application/json"
				},
				responseType: 'json'
			});
		return response[0].val;
	}

	async saveProp(key, value) {
		await this.#httpClient
			.fetch(`${this.#endpointUrl}/prop/${encodeURIComponent(key)}`, {
				method: 'PUT',
				headers: {
					"Content-Type": "text/plain"
				},
				body: value
			});
	}

	async getMaxUploadMb() {
		return await this.getProp('max_injection_upload_mb');
	}

	/**
	 * @returns {Promise<AliasColors>}
	 */
	async getAliasColors() {
		return AliasColors.parseServerString(await this.getProp('alias_colors'));
	}

	async getNews(){
		return JSON.parse(await this.getProp('home_news'))
	}

	/**
	 * @param {string[]} str
	 * @returns {Promise<void>}
	 */
	async addNews(str){
		await this.saveProp('home_news', createNewsString(str))
	}
}
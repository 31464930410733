export default class FragmentType {
    static LOSE_NEUTRAL = new FragmentType(0b000);
    static GAIN_NEUTRAL = new FragmentType(0b001);
    static LOSE_NEGATIVE = new FragmentType(0b100);
    static GAIN_NEGATIVE = new FragmentType(0b101);
    static LOSE_POSITIVE = new FragmentType(0b110);
    static GAIN_POSITIVE = new FragmentType(0b111);

    static #effectMask = 1 << 0; // 0 - lose, 1 - gain
    static #polarityMask = 1 << 1; // 0 - negative, 1 - positive
    static #hasChargeMask = 1 << 2; // 0 - no charge, 1 - has charge

    #value;

    constructor(val) {
        this.#value = val;
    }

    get numericValue() {
        return this.#value;
    }

    get polarity() {
        return (this.#value & FragmentType.#hasChargeMask) !== FragmentType.#hasChargeMask ? 0
            : (this.#value & FragmentType.#polarityMask) === FragmentType.#polarityMask ? 1
                : -1;
    }

    getGainOrLoseSign() {
        return this.#isGain() ? "+" : "-";
    }

    getSignedMaxCountValue(maxCountAbs) {
        return this.#isGain() ? maxCountAbs : -maxCountAbs;
    }

    equals(other) {
        if (this === other) return true;
        if (!(other instanceof FragmentType)) return false;
        return this.#value === other.#value;
    }

    /** @returns {FragmentType} */
    static fromIonConfig(charge, maxCount) {
        let val = 0;
        if (charge !== 0)
            val += FragmentType.#hasChargeMask;
        if (charge > 0)
            val += FragmentType.#polarityMask;
        if (maxCount > 0)
            val += FragmentType.#effectMask;
        return new FragmentType(val);
    }

    #isGain() {
        return (this.#value & FragmentType.#effectMask) === FragmentType.#effectMask;
    }
}
import DialogUtils from "../DialogUtils.js";
import {safeHtml} from "../../js/util/CommonUtils.js"

export default class ErrorPopup {

	#rootElement;

	#id;
	constructor(id = 'error-popup') {
		this.#id = id;
	}

	show(errorMessage) {
		document.body.insertAdjacentHTML('beforeend', this.#htmlTemplate(errorMessage));
		this.#rootElement = document.body.lastElementChild.querySelector(`#${this.#id}`);
		DialogUtils.addEscAndEnterHandler(this.#rootElement);
		this.#rootElement.querySelector('.btn-cancel').addEventListener('click', this.#remove.bind(this));
	}

	isAlreadyShown() {
		return !!document.getElementById(this.#id);
	}

	#remove() {
		this.#rootElement.parentElement.remove();
	}

	#htmlTemplate(errorMessage) {
		return safeHtml`
			<div class="page-overlay">
				<div class="popup popup--error" id="${this.#id}" tabindex="0">
					<div class="popup__main-content">
						<div class="labeled-text error-text">
						  <span class="labeled-text--label">Error</span>
						  <span class="labeled-text--text">${errorMessage}</span>
						</div>
					</div>
					<footer class="dialog-buttons-row">
						<button class="button button--text btn-cancel">Close</button>
					</footer>
				</div>
			</div>`;
	}
}
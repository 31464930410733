/**
 * Enum-like structure for Access Levels.
 * @readonly
 * @enum {number}
 */
const AccessLevels = Object.freeze({
    NONE: 0,
    READ: 2,
    FIND: 4,
    CREATE: 64,
    WRITE: 128,
    ADMIN: 4096,
    OWNER: 8192
});

/**
 * @class AccessLevel
 * Represents different access levels with an integer value.
 */
export default class AccessLevel {
    #intValue;

    /**
     * @param {number} intValue - The integer value representing the access level.
     */
    constructor(intValue) {
        if (typeof intValue !== 'number') {
            throw new TypeError('intValue must be a number');
        }
        this.#intValue = intValue;
    }

    /** @returns {number} */
    intValue(){
        return this.#intValue;
    }

    /**
     * Checks if the current access level meets or exceeds the specified access level.
     * @param {AccessLevel} other - Another AccessLevel instance to compare with.
     * @returns {boolean} - True if this access level meets or exceeds the other, false otherwise.
     */
    meetsOrExceeds(other) {
        if (!(other instanceof AccessLevel)) {
            throw new TypeError('Argument must be an instance of AccessLevel');
        }
        return this.#intValue >= other.#intValue;
    }

    lessThan(other) {
        if (!(other instanceof AccessLevel)) {
            throw new TypeError('Argument must be an instance of AccessLevel');
        }
        return this.#intValue < other.#intValue;
    }

    /**
     * Returns the string representation of the access level.
     * @returns {string} - String representation of the access level.
     */
    toString() {
        switch (this.#intValue) {
            case AccessLevels.NONE:
                return 'NONE';
            case AccessLevels.READ:
                return 'READ';
            case AccessLevels.FIND:
                return 'FIND';
            case AccessLevels.CREATE:
                return 'CREATE';
            case AccessLevels.WRITE:
                return 'WRITE';
            case AccessLevels.ADMIN:
                return 'ADMIN';
            case AccessLevels.OWNER:
                return 'OWNER';
            default:
                return `UNKNOWN(${this.#intValue})`;
        }
    }

    static NONE = new AccessLevel(AccessLevels.NONE);
    static READ = new AccessLevel(AccessLevels.READ);
    static FIND = new AccessLevel(AccessLevels.FIND);
    static CREATE = new AccessLevel(AccessLevels.CREATE);
    static WRITE = new AccessLevel(AccessLevels.WRITE);
    static ADMIN = new AccessLevel(AccessLevels.ADMIN);
    static OWNER = new AccessLevel(AccessLevels.OWNER);
}
import DialogUtils from "../DialogUtils.js";
import {urlFriendlyName} from "../../js/util/CommonUtils.js";

export default class CreateOrgDialogHtml {
    /** @type {HTMLDialogElement}  */
    #dialog
    #callbacks = {
        onSave: [],
        onCancel: [],
    }

    show() {
        this.#dialog = document.body.insertAdjacentElement("beforeend", this.#createDialogElement());
        this.#addListeners();
        this.#dialog.showModal()
    }

    remove() {
        this.#dialog.remove();
        this.#callbacks.onCancel.forEach(cb => cb())
    }

    /** @param {function} callback */
    onSave(callback) {
        this.#callbacks.onSave.push(callback);
    }

    /** @param {function} callback */
    onCancel(callback) {
        this.#callbacks.onCancel.push(callback);
    }

    /**
     * @param {string} inputName
     * @param {string} message
     */
    showInputErrorMessage(inputName, message) {
        const input = this.#dialog.querySelector(`[data-id="${inputName}"]`);
        if (input)
            input.errorMessage = message;
        this.#getPromptEl().classList.toggle('visually-hidden', true)
    }

    /** @returns {TextInput} */
    #getNameInput() {
        return this.#dialog.querySelector('[data-id="name"]')
    }

    /** @returns {HTMLElement} */
    #getPromptEl() {
        return this.#dialog.querySelector('[data-id="prompt"]')
    }

    /** @returns {HTMLElement} */
    #getUrlEl() {
        return this.#dialog.querySelector('[data-id="url"]')
    }

    /** @returns {HTMLElement} */
    #getSaveButtonEl() {
        return this.#dialog.querySelector('button[data-action="save"]')
    }

    /** @returns {HTMLElement} */
    #getCancelButtonEl() {
        return this.#dialog.querySelector('button[data-action="cancel"]')
    }

    #onSave() {
        if (!this.#getNameInput().checkValidity())
            return
        this.#callbacks.onSave.forEach(cb => cb(this.#getNameInput().value));
    }

    #addListeners() {
        DialogUtils.setDefaultDialogListeners(this.#dialog);
        this.#getNameInput().addEventListener('input', this.#togglePromptVisibility.bind(this));
        this.#getSaveButtonEl().addEventListener('click', this.#onSave.bind(this));
        this.#getCancelButtonEl().addEventListener('click', this.remove.bind(this));
        this.#dialog.addEventListener('close', this.remove.bind(this));
    }

    #togglePromptVisibility() {
        const name = this.#getNameInput().value;
        this.#getUrlEl().textContent = `https://peaksel.elsci.io/${urlFriendlyName(name)}`;
        this.#getPromptEl().classList.toggle('visually-hidden', !this.#getNameInput().value.length)
    }

    /** @returns {HTMLDialogElement} */
    #createDialogElement() {
        const el = document.createElement("dialog");
        el.classList.add('create-organization-dialog');
        el.innerHTML = this.#template();
        return el;
    }

    /** @returns {string} */
    #template() {
        return `
            <div class="create-organization-dialog__content dialog-content">
                <text-input data-id="name" label="Organization name *" type="text" maxlength="60" required></text-input>
                <div class="create-organization-dialog__prompt-row">
                    <p data-id="prompt" class="create-organization-dialog__prompt visually-hidden">
                        The URL will be (can't be change later): <br> 
                        <span data-id="url" class="create-organization-dialog__url"></span> <br> 
                    </p>
                </div>
            </div>
            <div class="create-organization-dialog__buttons dialog-buttons">
                <button data-action="save" class="button button--contained btn-ok">Create</button>
                <button data-action="cancel" class="button button--text btn-cancel">Cancel</button>
            </div>
       `
    }
}
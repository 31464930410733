import CreateOrgDialogHtml from "./CreateOrgDialogHtml.js";

export default class CreateOrgDialogCmp {
    /** @type {CreateOrgDialogHtml}  */
    #html = new CreateOrgDialogHtml();

    show() {
        this.#html.show()
    }

    remove() {
        this.#html.remove()
    }

    /** @param {function} callback */
    onSave(callback) {
        this.#html.onSave(callback);
    }

    /** @param {function} callback */
    onCancel(callback) {
        this.#html.onCancel(callback);
    }

    /** @param {{}[]}errors */
    showInputErrors(errors) {
        for (const error of errors)
            this.#html.showInputErrorMessage(error.field, error.message)
    }
}
import Range from '../../Range.js';
import IntegrationPeakFilters from './IntegrationPeakFilters.js';
import PeakDetectorProperties from './PeakDetectorProperties.js';

export default class IntegrationAlgorithm {

    /**
     * @param {Range} integrableRange
     * @param {IntegrationPeakFilters} integrationPeakFilters
     * @param {PeakDetectorProperties} peakDetectorProperties
     */
    constructor(integrableRange, integrationPeakFilters, peakDetectorProperties) {
        this._integrableRange = Object.freeze(integrableRange);
        this._integrationPeakFilters = Object.freeze(integrationPeakFilters);
        this._peakDetectorProperties = Object.freeze(peakDetectorProperties);
    }

    /**
     * @param {Range} range
     * @return {IntegrationAlgorithm}
     */
    withIntegrableRange(range) {
        return new IntegrationAlgorithm(range, this.peakFilters, this.peakDetectorProperties);
    }

    /**
     * @param {IntegrationPeakFilters} peakFilters
     * @return {IntegrationAlgorithm}
     */
    withIntegrationPeakFilters(peakFilters) {
        return new IntegrationAlgorithm(this.integrableRange, peakFilters, this.peakDetectorProperties);
    }

    /**
     * @param {PeakDetectorProperties} peakDetectorProps
     * @return {IntegrationAlgorithm}
     */
    withPeakDetectorProperties(peakDetectorProps) {
        return new IntegrationAlgorithm(this.integrableRange, this.peakFilters, peakDetectorProps);
    }


    /** @return {Readonly<Range>} */
    get integrableRange() {
        return this._integrableRange;
    }
    /** @return {Readonly<IntegrationPeakFilters>} */
    get peakFilters() {
        return this._integrationPeakFilters;
    }
    /** @return {Readonly<PeakDetectorProperties>} */
    get peakDetectorProperties() {
        return this._peakDetectorProperties;
    }

    /** @return {string} */
    get peakDetectingAlgorithmName() {
        return this._peakDetectorProperties.algorithmName;
    }
    /** @return {string} */
    get smootherAlgorithmName() {
        return this._peakDetectorProperties.smootherName;
    }
    /** @return {number} */
    get curvatureThreshold() {
        return this._peakDetectorProperties.curvatureThreshold;
    }
    /** @return {number} */
    get windowSize(){
        return this._peakDetectorProperties.windowSize;
    }
    /** @return {number} */
    get polynomialOrder(){
        return this._peakDetectorProperties.polynomialOrder;
    }
    /** @return {number} */
    get liftoffThreshold(){
        return this._peakDetectorProperties.liftoffThreshold;
    }
    /** @return {number} */
    get touchdownThreshold(){
        return this._peakDetectorProperties.touchdownThreshold;
    }
    /** @return {number} */
    get minPeakArea(){
        return this._integrationPeakFilters.minPeakArea;
    }

    toServerJson() {
        return {
            integrationStart: this._integrableRange.lower,
            integrationEnd: this._integrableRange.upper,
            minPeakArea: this._integrationPeakFilters.minPeakArea,
            peakDetector: this.peakDetectorProperties.toServerJson()
        }
    }
    static parseServerJson(json) {
        return new IntegrationAlgorithm(
            new Range(json.integrationStart, json.integrationEnd),
            new IntegrationPeakFilters(json.minPeakArea),
            PeakDetectorProperties.parseServerJson(json.peakDetector));
    }
}
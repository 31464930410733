import HomePageHeaderHtml from "./HomePageHeaderHtml.js";
import SignInSectionCmp from "../../../components/page-header/SignInSectionCmp.js";

export default class HomePageHeaderCmp {
    #html = new HomePageHeaderHtml();
    /** @type {SignInSectionCmp} */
    #singInSectionCmp = new SignInSectionCmp();
    /** @type {UserHeaderCmp} */
    #userHeaderCmp;

    /** @param {UserHeaderCmp} userHeaderCmp */
    constructor(userHeaderCmp) {
        this.#userHeaderCmp = userHeaderCmp;
    }

    init(pageHeaderEl) {
        this.#html.init(pageHeaderEl);
        this.#singInSectionCmp.init(this.#html.getUserHeaderElement());
        this.#userHeaderCmp.init(this.#html.getUserHeaderElement());
    }

    /**
     * @param {SecurityContext} securityContext
     */
    setSecurityContext(securityContext) {
        this.#singInSectionCmp.setSecurityContext(securityContext);
        if (securityContext.isAuthenticated())
            this.#userHeaderCmp.show(securityContext.getCurrentUser());
    }
}
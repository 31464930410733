export default class OrgSettingsPageViewMode {
	static NAMES = Object.freeze({
		TEAMS_LIST: {name: 'teams-list', breadcrumbsName: "Teams"},
		TEAM: {name: 'team', additionalParamsForUrl: ['team-id']},
		BILLING: {name: 'billing', breadcrumbsName: "Billing"},
		ORG_MEMBER: {name: 'users', breadcrumbsName: "Users"},
		CRAWLER_LIST: {name: 'import', breadcrumbsName: "Import"},
	});
	/** @type {string} */
	#currentMode;

	constructor() {
		this.#currentMode = OrgSettingsPageViewMode.NAMES.TEAMS_LIST.name;
	}

	/** @returns {string} */
	getCurrentMode() {
		return this.#currentMode;
	}

	enterTeamsListMode() {
		this.#currentMode = OrgSettingsPageViewMode.NAMES.TEAMS_LIST.name;
	}

	enterTeamMode() {
		this.#currentMode = OrgSettingsPageViewMode.NAMES.TEAM.name;
	}

	enterBillingMode() {
		this.#currentMode = OrgSettingsPageViewMode.NAMES.BILLING.name;
	}

	enterOrgMemberMode() {
		this.#currentMode = OrgSettingsPageViewMode.NAMES.ORG_MEMBER.name;
	}

	enterCrawlerMode() {
		this.#currentMode = OrgSettingsPageViewMode.NAMES.CRAWLER_LIST.name;
	}

	/** @returns {boolean} */
	isTeamsListMode() {
		return this.#currentMode === OrgSettingsPageViewMode.NAMES.TEAMS_LIST.name;
	}

	/** @returns {boolean} */
	isTeamMode() {
		return this.#currentMode === OrgSettingsPageViewMode.NAMES.TEAM.name;
	}

	/** @returns {boolean} */
	isBillingMode() {
		return this.#currentMode === OrgSettingsPageViewMode.NAMES.BILLING.name;
	}

	/** @returns {boolean} */
	isOrgMemberMode() {
		return this.#currentMode === OrgSettingsPageViewMode.NAMES.ORG_MEMBER.name;
	}

	/** @returns {boolean} */
	isCrawlerMode() {
		return this.#currentMode === OrgSettingsPageViewMode.NAMES.CRAWLER_LIST.name;
	}

	getAllAdditionalParams(){
		const additionalParams = [];
		for (const mode of Object.values(OrgSettingsPageViewMode.NAMES)) {
			if (mode.additionalParamsForUrl){
				mode.additionalParamsForUrl.forEach(p => additionalParams.push(p))
			}
		}
		return additionalParams;
	}
}
import {safeHtml} from "../../util/CommonUtils.js";

export default class CreateBatchDialog {
    /** @type {HTMLDialogElement} */
    #dialog;
    #callBacks = {};
    /** @type {string} */
    #filter;

    /**
     * @param {string} fileNameFilter
     * @param {number} totalInjectionsCount
     * @returns {CreateBatchDialog}
     */
    render(fileNameFilter, totalInjectionsCount) {
        if (this.#dialog)
            this.#dialog.remove();
        this.#dialog = document.body.insertAdjacentElement("beforeend",
            this.#createDialogElement(fileNameFilter, totalInjectionsCount));
        this.#filter = fileNameFilter;
        let batchName = this.#dialog.querySelector('#batch-name');

        batchName.addEventListener('input', () => {
            okBtn.toggleAttribute('disabled', batchName.value.trim().length === 0);
        })

        let cancelBtn = this.#dialog.querySelector('.btn-cancel');
        cancelBtn.addEventListener('click', () => {
            this.remove();
        })

        let okBtn = this.#dialog.querySelector('.btn-ok');
        okBtn.addEventListener('click', async () => {
            this.#dialog.querySelector('.progress').style.display = 'block';
            this.#dialog.querySelectorAll('text-input').forEach(el => {
                el.setDisabled(true);
            });
            this.#dialog.querySelectorAll('button').forEach(el => {
                el.setAttribute('disabled', true);
            });
            if (typeof this.#callBacks['create'] === "function")
                await this.#callBacks['create']({
                    batchName: batchName.value.trim(),
                    injectionNameFilter: this.#filter
                });
        })

        this.#dialog.addEventListener('keydown', (evt) => {
            evt.stopPropagation();
            if (evt.repeat) return;
            switch (evt.key) {
                case 'Enter' : {
                    if (!okBtn.hasAttribute('disabled')) {
                        okBtn.dispatchEvent(new Event('click'))
                    }
                    break;
                }
                case 'Escape' : {
                    cancelBtn.dispatchEvent(new Event('click'))
                }
            }
        })
        this.#dialog.showModal();
        batchName.focus();

        return this;
    }

    remove() {
        this.#dialog.remove();
    }

    on(eventName, cb){
        this.#callBacks[eventName] = cb;
    }

    /**
     * @param {string} fileNameFilter
     * @param {number} totalInjectionsCount
     * @returns {HTMLDialogElement}
     */
    #createDialogElement(fileNameFilter, totalInjectionsCount) {
        const el = document.createElement("dialog");
        el.setAttribute('id', 'create-batch-dialog');
        el.innerHTML = CreateBatchDialog.#htmlTemplate(fileNameFilter, totalInjectionsCount);
        return el;
    }

    /**
     * @param {string} fileNameFilter
     * @param {number} totalInjectionsCount
     * @returns {string}
     */
    static #htmlTemplate(fileNameFilter, totalInjectionsCount) {
        const disabledAttr = fileNameFilter.length ? "" : "disabled"
        return safeHtml`
              <div class="progress">
                <div class="indeterminate"></div>
              </div>
              <div class="dialog-content">
                <text-input id="batch-name" label="Batch name" minLength="1" maxLength="255" value="${fileNameFilter}">
                </text-input>
                <div id="confirmation-sign">
                  <span>The batch will contain </span>
                  <span id="total-filtered">${totalInjectionsCount}</span>
                  <span> injection(s).</span>
                </div>
              </div>
              <div class="dialog-buttons-row">
                <button class="button button--text btn-cancel">Cancel</button>
                <button class="button button--contained btn-ok" ${disabledAttr}>Next</button>
              </div>
            `
        ;
    }
}
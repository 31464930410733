import HttpClient from "../../js/repository/HttpClient.js";
import UserHeaderCmp from "../../components/page-header/UserHeaderCmp.js";
import OrgRepository from "../../js/repository/OrgRepository.js";
import UserRepository from "../../js/repository/UserRepository.js";
import HomePageCmp from "./HomePageCmp.js";
import HomePageHeaderCmp from "./HomePageHeader/HomePageHeaderCmp.js";
import GlobalSettingsRepository from "../../js/repository/GlobalSettingsRepository.js";
import InjectionRepository from "../../js/repository/InjectionRepository.js";
import BatchRepository from "../../js/repository/BatchRepository.js";

export default class HomePageIoC {
	/** @type {Map<string, any>} */
	#cached = new Map();
	/** @type {SecurityContext} */
	#securityContext;

	/**
	 * @param {SecurityContext} securityContext
	 * @see {HttpClient#createSecurityContext}
	 */
	constructor(securityContext) {
		if (!securityContext)
			throw new Error('Security context is required');
		this.#securityContext = securityContext;
		/** @type {Map<string, any>} */
		this.#cached = new Map();
	}

	/** @return {HomePageCmp} */
	getHomePageCmp() {
		return this.#cache('homePageCmp', () => new HomePageCmp(this.#securityContext, this));
	}

	/** @returns {HomePageHeaderCmp} */
	getPageHeaderCmp() {
		return this.#cache('homePageHeaderCmp', () => new HomePageHeaderCmp(this.getUserHeaderCmp()));
	}

	/** @returns {UserHeaderCmp} */
	getUserHeaderCmp(){
		return this.#cache("userHeaderCmp", ()=> new UserHeaderCmp(this.getOrganizationRepository()))
	}

	/** @returns {OrgRepository} */
	getOrganizationRepository() {
		return this.#cache('organizationRepo', () => new OrgRepository(this.getHttpClient()));
	}

	/** @returns {UserRepository} */
	getUserRepository() {
		return this.#cache('userRepository', () => new UserRepository(this.getHttpClient()));
	}

	/** @returns {GlobalSettingsRepository} */
	getGlobalSettingsRepository() {
		return this.#cache('globalSettingsRepository', () => new GlobalSettingsRepository(this.getHttpClient()));
	}

	/** @return {InjectionRepository} */
	getInjectionRepository() {
		return this.#cache('injectionRepo', () => new InjectionRepository(this.getHttpClient()))
	}

	/** @returns {BatchRepository} */
	getBatchRepository() {
		return this.#cache('batchRepo', () => new BatchRepository(this.getHttpClient()));
	}

	/** @returns {HttpClient} */
	getHttpClient() {
		return this.#cache('httpClient', () => new HttpClient('', this.#securityContext));
	}

	/**
	 * @returns {any}
	 * @private
	 */
	#cache(cmpName, createCmpFn) {
		let cmp = this.#cached.get(cmpName);
		if (!cmp) {
			cmp = createCmpFn();
			this.#cached.set(cmpName, cmp);
		}
		return cmp;
	}
}
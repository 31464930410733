import DialogUtils from "../DialogUtils.js";
import HtmlElements from "../../js/util/HtmlElements.js";

export default class AddInjectionDialogHtml {
    /** @type {HTMLDialogElement}  */
    #dialog;
    #onUpload = ()=>{};
    #onCancel = ()=>{}

    show() {
        this.#dialog = document.body.insertAdjacentElement("beforeend", this.#createDialogElement());
        this.#addListeners();
        this.#dialog.showModal();
    }

    remove() {
        this.#onCancel();
        this.#dialog.remove();
    }

    /** @param {function} callback */
    onUpload(callback) {
        this.#onUpload = callback;
    }

    /** @param {function} callback */
    onCancel(callback) {
        this.#onCancel = callback;
    }

    /** @returns {HTMLElement} */
    getDropArea(){
        return this.#dialog.querySelector('.add-injection-dialog__drop-area');
    }

    #uploadInjection() {
        this.#onUpload(this.#getBrowseFileInput().files[0]);
    }

    #addListeners() {
        DialogUtils.setDefaultDialogListeners(this.#dialog);
        this.#getCancelButton().addEventListener('click', this.remove.bind(this));
        // Input type="file" is hidden, and we use span.add-injection-dialog__link to get access to the input
        // When user click on span, we simulate click on input
        this.#getBrowseFileLink().addEventListener('click', ()=>{
            this.#getBrowseFileInput().click()
        })
        this.#getBrowseFileInput().addEventListener('change', this.#uploadInjection.bind(this));
    }

    /** @returns {HTMLElement} */
    #getCancelButton(){
        return this.#dialog.querySelector('[data-action="cancel"]')
    }

    /** @returns {HTMLElement} */
    #getBrowseFileLink(){
        return this.#dialog.querySelector('.add-injection-dialog__link')
    }

    /** @returns {HTMLInputElement} */
    #getBrowseFileInput(){
        return this.#dialog.querySelector('#browse-file')
    }

    /** @returns {HTMLDialogElement} */
    #createDialogElement() {
        const el = document.createElement("dialog");
        el.classList.add('add-injection-dialog');
        el.innerHTML = this.#template();
        return el;
    }

    /** @returns {string} */
    #template() {
        // We set to input type="file" display none, because this input has an inappropriate appearance
        // And use span.add-injection-dialog__link to get access to input
        return `
            <div class="add-injection-dialog__content dialog-content">
                <div class="add-injection-dialog__row">
                    <div class="add-injection-dialog__row-title">1. Zip your raw folder / file</div>
                    <div class="add-injection-dialog__row-subtitle">You can place multiple raw folders/files into one archive</div>
                </div> 
                <div class="add-injection-dialog__row">
                    <div class="add-injection-dialog__row-title">2. Drop in the area below</div>
                </div>
                <div class="add-injection-dialog__drop-area">
                    ${HtmlElements.iconByName('upload', "add-injection-dialog__upload-icon")}
                    <div class="add-injection-dialog__drop-area-description">Drop zip here or 
                        <span class="add-injection-dialog__link">browse files</span>
                        <input type="file" id="browse-file" class="add-injection-dialog__link" style="display: none" accept=".zip,application/zip"></div>
                </div>
                
                <a href="https://elsci.io/docs/peaksel/dataimport/import-manual-upload.html" target="_blank">
                  <span class="add-injection-dialog__help-icon material-icons" title="Manual upload of raw data">help_outline</span>
                </a>
            </div>
            <div class="add-injection-dialog__buttons dialog-buttons">
                <button data-action="cancel" class="button button--text btn-cancel">Cancel</button>
            </div>
       `
    }
}
import {convertIntToLetters} from '../../util/CommonUtils.js';

/**
 * The main purpose of this class is to hold well coordinates and convert them in user-friendly string.
 */
export default class OnPlatePosition {
	/**
	 * @param {number} row
	 * @param {number} col
	 */
	constructor(row, col) {
		if (typeof row !== 'number' || typeof col !== 'number')
			throw new Error(`Invalid type of row or col, ${row} ${col}`);
		this._row = row;
		this._col = col;
	}

	get rowIndex() {
		return this._row;
	}

	get colIndex() {
		return this._col;
	}

	get rowNumber() {
		return this._row + 1;
	}

	get colNumber() {
		return this._col + 1;
	}

	/**
	 * Converts zero-based numeric coordinates to string where row becomes letter(s) and column becomes 1 based.
	 * Used for displaying on-plate position column in visualization table.
	 * @returns {string}
	 */
	toUserString() {
		return convertIntToLetters(this._row) + ":" + (this._col + 1);
	}
}
